import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { reducer as formReducer } from 'redux-form';
import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';
import thunk from 'redux-thunk';

import persist from '../lib/persist-saga';

// TODO(redux): remove this dependency (refactor how auth/app state is persisted via hooks?)
const { saga: persistSaga, getInitialState } = persist({
  selector: (state) => ({
    app: state.app,
  }),
  throttle: 1000,
});

function* rootSaga() {
  return yield all([persistSaga()]);
}

const initialize = (initialState) => {
  const sagaMiddleware = createSagaMiddleware();
  const middleware = [thunk, sagaMiddleware];

  const middlewareEnhancer = applyMiddleware(...middleware);

  const enhancers = [middlewareEnhancer];

  const composedEnhancers = composeWithDevTools(...enhancers);

  const createStoreWithMiddleware = compose(composedEnhancers)(createStore);

  const state = initialState || getInitialState();
  const store = createStoreWithMiddleware(
    combineReducers({
      form: formReducer,
    }),
    state,
  );

  (store as any).runSagaTask = () => {
    (store as any).sagaTask = sagaMiddleware.run(rootSaga);
  };

  (store as any).runSagaTask();
  return store;
};

export default initialize;
