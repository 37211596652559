import { ICompanyHRISImportResponse } from '@screens/Shared/CompanyUploadFileModal/CompanyUploadFileModal';
import {
  DjangoList,
  EDocumentResultVM,
  ICalendarVM,
  ICompanyVM,
  ICoreStepTemplateVM,
  IFileVM,
  IFlagVM,
  IIntegrationLeaveLog,
  IIntegrationPayLog,
  IIntegrationVM,
  ILeaveAdminVM,
  ILeaveResultVM,
  IMessageResultVM,
  INotesResultVM,
  INoteVM,
  InsuranceProviderResponseVM,
  IPayCalcItemTemplateVM,
  IPayCalcVM,
  IPerLeaveBenefitUsage,
  IPlanAdminVM,
  IPolicyResultVM,
  IProfileVM,
  IScheduleDaysVM,
  ISearchResultVM,
  IStepTemplateVM,
  ITaskResultVM,
  ITaskTemplateResultVM,
  IUserVM,
  MonthlyPayHistory,
  IBenefitDenial,
  IStepVM,
  IRolePermissionVM,
  IFMLADocumentCheck,
} from '@types';
import { IAction, IDocument, IDocumentResult, IDocumentType } from '@constants/types';
import { UseMutationResult } from '@tanstack/react-query';
import { IHolidayVM } from '@screens/Admin/PlanDetailsPage/components/RightFlyout/CompanyDetails/types';

import { BASE_API_PATH, BASE_AUTH_API_PATH } from './constants';
import QueryKeys from './queryKeys';
import { mutate, query } from './request-lib';

const BASE_ADMIN_API_PATH = `${BASE_API_PATH}/admin`;
const ADMIN_PATHS = {
  BASE: BASE_ADMIN_API_PATH,
  ACTIVITIES: `${BASE_ADMIN_API_PATH}/activities`,
  BENEFITS: `${BASE_API_PATH}/benefits/admin`,
  COMPANIES: `${BASE_ADMIN_API_PATH}/companies`,
  DOCUMENTS: `${BASE_API_PATH}/documents/admin`,
  INTEGRATIONS: `${BASE_API_PATH}/integrations/admin/integrations`,
  MONTHLY_PAY_HISTORY: `${BASE_API_PATH}/integrations/admin/monthly-pay-history`,
  NOTIFICATIONS: `${BASE_API_PATH}/notifications/admin`,
  PAYCALCS: `${BASE_ADMIN_API_PATH}/paycalcs`,
  PAYCALC_ITEMS: `${BASE_ADMIN_API_PATH}/paycalcitems`,
  PAYCALC_ITEM_TEMPLATES: `${BASE_ADMIN_API_PATH}/paycalcitemstemplate`,
  PLANS: `${BASE_ADMIN_API_PATH}/plans`,
  TASKS: `${BASE_ADMIN_API_PATH}/tasks`,
  TASK_TEMPLATES: `${BASE_ADMIN_API_PATH}/tasktemplates`,
  TRACKING: `${BASE_API_PATH}/tracking/admin`,
  USERS: `${BASE_ADMIN_API_PATH}/users`,
};

const Admin = {
  Integration: {
    getMany: (params = {}, options = {}) =>
      query<DjangoList<IIntegrationVM>>('get', `${ADMIN_PATHS.INTEGRATIONS}/`, params, options),
    MonthlyPayHistory: {
      saveMonthlyPayHistory: (options = {}) =>
        mutate('post', `${ADMIN_PATHS.MONTHLY_PAY_HISTORY}/save-employee-monthly-pay-history/`, options),
      getMonthlyPayHistory: (params = {}, options = {}) =>
        query<MonthlyPayHistory[]>(
          'get',
          `${ADMIN_PATHS.MONTHLY_PAY_HISTORY}/get-employee-monthly-pay-history/?leave_id=:leaveId`,
          params,
          options,
        ),
    },
  },
  IntegrationLogs: {
    getMany: (params = {}, options = {}) =>
      query<Record<string, IIntegrationLeaveLog[] | IIntegrationPayLog[]>>(
        'get',
        `${BASE_API_PATH}/integration-logs/admin/integration-logs/`,
        params,
        options,
      ),
  },
  Search: {
    getResults: (params = {}, options = {}) =>
      query<ISearchResultVM[]>('get', `${ADMIN_PATHS.BASE}/search/global-search/`, params, options),
  },
  Action: {
    getMany: (params = {}, options = {}) =>
      query<DjangoList<IAction>>('get', `${ADMIN_PATHS.ACTIVITIES}/feed/`, params, options),
    getOne: (params = {}, options = {}) =>
      query<DjangoList<IAction>>('get', `${ADMIN_PATHS.ACTIVITIES}/feed/:actionId/`, params, options),
  },
  Benefits: {
    Denials: {
      getMany: (params = {}, options = {}) =>
        query<DjangoList<IBenefitDenial>>('get', `${ADMIN_PATHS.BENEFITS}/denials/`, params, options),
      create: (options = {}) => mutate('post', `${ADMIN_PATHS.BENEFITS}/denials/`, options),
      update: (options = {}) => mutate('patch', `${ADMIN_PATHS.BENEFITS}/denials/:denialId/`, options),
      delete: (options = {}) => mutate('delete', `${ADMIN_PATHS.BENEFITS}/denials/:denialId/`, options),
    },
  },
  Company: {
    getOne: (params = {}, options = {}) =>
      query<ICompanyVM>('get', `${ADMIN_PATHS.COMPANIES}/:companyId/`, params, options),
    getMany: (params = {}, options = {}) =>
      query<DjangoList<ICompanyVM>>('get', `${ADMIN_PATHS.COMPANIES}/`, params, options),
    importCsv: (options = {}): UseMutationResult<ICompanyHRISImportResponse> =>
      mutate('post', `${ADMIN_PATHS.COMPANIES}/:companyId/import-csv/`, {
        isUpload: true,
        ...options,
      }),
    performOffboardingSteps: (options = {}) =>
      mutate('get', `${ADMIN_PATHS.COMPANIES}/:companyId/perform-offboarding-steps/`, options),
    generateOffboardingPayrollReport: (params = {}, options = {}) =>
      query('get', `${ADMIN_PATHS.COMPANIES}/:companyId/offboarding-payroll-report/`, params, {
        enabled: false,
        ...options,
      }),
    update: (options = {}) => mutate('patch', `${ADMIN_PATHS.COMPANIES}/:companyId/`, { ...options, isUpload: true }),
    create: (options = {}) => mutate('post', `${ADMIN_PATHS.COMPANIES}/`, { ...options, isUpload: true }),
    delete: (options = {}) => mutate('delete', `${ADMIN_PATHS.COMPANIES}/:companyId/`, options),
    Calendar: {
      getDetails: (params = {}, options = {}) =>
        query<IPayCalcVM>('get', `${BASE_API_PATH}/calendars/admin/calendars/paycalc-details/`, params, options),
      getMany: (params = {}, options = {}) =>
        query<DjangoList<ICalendarVM>>('get', `${BASE_API_PATH}/calendars/admin/calendars/`, params, options),
      Holidays: {
        getMany: (params = {}, options = {}) =>
          query<DjangoList<IHolidayVM>>('get', `${BASE_API_PATH}/calendars/admin/holidays/`, params, options),
      },
    },
    Policy: {
      getOne: (params = {}, options = {}) =>
        query<IPolicyResultVM>('get', `${ADMIN_PATHS.COMPANIES}/:companyId/policy/`, params, options),
    },
    DocumentTypes: {
      getMany: (params = {}, options = {}) => query('get', `${ADMIN_PATHS.DOCUMENTS}/document-types/`, params, options),
      create: (options = {}) =>
        mutate('post', `${ADMIN_PATHS.DOCUMENTS}/document-types/`, { ...options, isUpload: true }),
      update: (options = {}) =>
        mutate('patch', `${ADMIN_PATHS.DOCUMENTS}/document-types/:documentTypeId/`, { ...options, isUpload: true }),
      getOne: (params = {}, options = {}) =>
        query<IDocumentType>('get', `${ADMIN_PATHS.DOCUMENTS}/document-types/:documentTypeId/`, params, options),
    },
  },
  CoreStepTemplate: {
    getMany: (params = {}, options = {}) =>
      query<DjangoList<ICoreStepTemplateVM>>(
        'get',
        `${ADMIN_PATHS.BASE}/coresteptemplates/`,
        params,
        options,
        {},
        QueryKeys.CoreStepTemplateGetMany,
      ),
    getOne: (params = {}, options = {}) =>
      query<ICoreStepTemplateVM>(
        'get',
        `${ADMIN_PATHS.BASE}/coresteptemplates/:templateId/`,
        params,
        options,
        {},
        QueryKeys.CoreStepTemplateGetOne,
      ),
    create: (options = {}) =>
      mutate<ICoreStepTemplateVM>('post', `${ADMIN_PATHS.BASE}/coresteptemplates/`, {
        ...options,
        isUpload: true,
      }),
    update: (options = {}) =>
      mutate<ICoreStepTemplateVM>('put', `${ADMIN_PATHS.BASE}/coresteptemplates/:templateId/`, {
        ...options,
        isUpload: true,
      }),
    delete: (options = {}) => mutate('delete', `${ADMIN_PATHS.BASE}/coresteptemplates/:templateId/`, options),
  },
  Documents: {
    getMany: (params = {}, options = {}) =>
      query<IDocument[]>(
        'get',
        `${ADMIN_PATHS.DOCUMENTS}/documents/`,
        params,
        options,
        {},
        QueryKeys.AdminDocumentsMany,
      ),
    getOne: (params = {}, options = {}) =>
      query<IDocument>('get', `${ADMIN_PATHS.DOCUMENTS}/documents/:documentId/`, params, options),
    create: (options = {}) => mutate('post', `${ADMIN_PATHS.DOCUMENTS}/documents/`, { ...options, isUpload: true }),
    update: (options = {}) =>
      mutate('patch', `${ADMIN_PATHS.DOCUMENTS}/documents/:documentId/`, {
        isUpload: true,
        ...options,
        retry: false,
      }),
    delete: (options = {}) => mutate('delete', `${ADMIN_PATHS.DOCUMENTS}/documents/:documentId/`, options),
  },
  DocumentRequest: {
    getMany: (params = {}, options = {}) =>
      query<DjangoList<IDocument>>('get', `${ADMIN_PATHS.DOCUMENTS}/document-requests/`, params, options),
    delete: (options = {}) =>
      mutate('delete', `${ADMIN_PATHS.DOCUMENTS}/document-requests/:documentRequestId/`, options),
  },
  DocumentResult: {
    getMany: (params = {}, options = {}) =>
      query<DjangoList<IDocumentResult>>('get', `${ADMIN_PATHS.DOCUMENTS}/document-results/`, params, options),
  },
  DocumentAudit: {
    getMany: (params = {}, options = {}) => query('get', `${BASE_API_PATH}/leaves/audits/documents/`, params, options),
  },
  File: {
    getMany: (params = {}, options = {}) =>
      query<DjangoList<IFileVM>>('get', `${ADMIN_PATHS.BASE}/files/`, params, options),
    getOne: (params = {}, options = {}) => query<IFileVM>('get', `${ADMIN_PATHS.BASE}/files/:fileId/`, params, options),
    getUploads: (params = {}, options = {}) =>
      query<IFileVM[]>('get', `${ADMIN_PATHS.BASE}/files/uploads/`, params, options),
    delete: (options = {}) => mutate('delete', `${ADMIN_PATHS.BASE}/files/:id/`, options),
  },
  Flag: {
    getMany: (params = {}, options = {}) =>
      query<DjangoList<IFlagVM>>('get', `${ADMIN_PATHS.BASE}/flags/`, params, options),
    update: (options = {}) =>
      mutate<IFlagVM>('patch', `${ADMIN_PATHS.BASE}/flags/:flagId/`, { isUpload: true, ...options }),
  },
  Leave: {
    getMany: (params = {}, options = {}) =>
      query<DjangoList<ILeaveAdminVM>>('get', `${ADMIN_PATHS.BASE}/leaves/`, params, options),
    getOne: (params = {}, options = {}) =>
      query<ILeaveResultVM>('get', `${ADMIN_PATHS.BASE}/leaves/:leaveId/`, params, options),
    update: (options = {}) =>
      mutate<ILeaveAdminVM>('patch', `${ADMIN_PATHS.BASE}/leaves/:leaveId/`, { isUpload: true, ...options }),
    delete: (options = {}) => mutate('delete', `${ADMIN_PATHS.BASE}/leaves/:leaveId/`, options),
    getManyDeleted: (params = {}, options = {}) =>
      query<DjangoList<ILeaveAdminVM>>('get', `${ADMIN_PATHS.BASE}/leaves/deleted/`, params, options),
    restoreDeleted: (options = {}) => mutate('patch', `${ADMIN_PATHS.BASE}/leaves/:leaveId/restore/`, options),
    HistoricalProfile: {
      getOne: (params = {}, options = {}) =>
        query<IProfileVM>('get', `${ADMIN_PATHS.BASE}/leaves/:leaveId/historical-profile/`, params, options),
    },
    Benefit: {
      getMany: (params = {}, options = {}) =>
        query<IPerLeaveBenefitUsage[]>('get', `${ADMIN_PATHS.BASE}/leaves/:leaveId/benefits/`, params, options),
    },
  },
  Notification: {
    getMany: (params = {}, options = {}) =>
      query<IMessageResultVM>('get', `${ADMIN_PATHS.NOTIFICATIONS}/`, params, options),
  },
  Note: {
    getMany: (params = {}, options = {}) => query<INotesResultVM>('get', `${ADMIN_PATHS.BASE}/notes/`, params, options),
    create: (options = {}) => mutate<INoteVM>('post', `${ADMIN_PATHS.BASE}/notes/`, options),
    update: (options = {}) => mutate('patch', `${ADMIN_PATHS.BASE}/notes/:noteId/`, options),
    delete: (options = {}) => mutate('delete', `${ADMIN_PATHS.BASE}/notes/:noteId/`, options),
  },
  Plan: {
    approve: (options = {}) => mutate('post', `${ADMIN_PATHS.PLANS}/:planId/approve/`, options),
    getMany: (params = {}, options = {}) =>
      query<DjangoList<IPlanAdminVM>>('get', `${ADMIN_PATHS.PLANS}/`, params, options, {}, QueryKeys.AdminPlanMany),
    getOne: (params = {}, options = {}) =>
      query<IPlanAdminVM>('get', `${ADMIN_PATHS.PLANS}/:planId/`, params, options, {}, QueryKeys.AdminPlanOne),
    update: (options = {}) => mutate('patch', `${ADMIN_PATHS.PLANS}/:planId/`, { isUpload: true, ...options }),
    generateTasks: (options = {}) => mutate('post', `${ADMIN_PATHS.PLANS}/:planId/generate-tasks/`, options),
    getCustomTags: (params = {}, options = {}) =>
      query<string[]>('get', `${ADMIN_PATHS.PLANS}/custom-tags/`, params, options),
    delete: (options = {}) => mutate('delete', `${ADMIN_PATHS.PLANS}/:planId/`, options),
    Leave: {
      getOne: (params = {}, options = {}) =>
        query<ILeaveResultVM>('get', `${ADMIN_PATHS.PLANS}/:planId/leave/`, params, options),
    },
    PayCalc: {
      getMany: (params = {}, options = {}) =>
        query<DjangoList<IPayCalcVM>>(
          'get',
          `${ADMIN_PATHS.PAYCALCS}/`,
          params,
          options,
          {},
          QueryKeys.AdminPlanPaycalcMany,
        ),
      update: (options = {}) => mutate('put', `${ADMIN_PATHS.PAYCALCS}/:paycalcId/`, options),
      partialUpdate: (options = {}) => mutate<IPayCalcVM>('patch', `${ADMIN_PATHS.PAYCALCS}/:paycalcId/`, options),
      generate: (options = {}) => mutate('post', `${ADMIN_PATHS.PAYCALCS}/`, options),
      publish: (options = {}) => mutate('patch', `${ADMIN_PATHS.PAYCALCS}/:paycalcId/publish/`, options),
      delete: (options = {}) => mutate('delete', `${ADMIN_PATHS.PAYCALCS}/:paycalcId/`, options),
      notifyPayroll: (options = {}) => mutate('post', `${ADMIN_PATHS.PLANS}/:planId/notify-payroll/`, options),
    },
    PayCalcItem: {
      create: (options = {}) => mutate('post', `${ADMIN_PATHS.PAYCALC_ITEMS}/`, options),
      update: (options = {}) => mutate('put', `${ADMIN_PATHS.PAYCALC_ITEMS}/:paycalcItemId/`, options),
      delete: (options = {}) => mutate('delete', `${ADMIN_PATHS.PAYCALC_ITEMS}/:paycalcItemId/`, options),
      bulk_delete: (options = {}) => mutate('delete', `${ADMIN_PATHS.PAYCALC_ITEMS}/bulk_delete/`, options),
      bulk_update: (options = {}) => mutate('post', `${ADMIN_PATHS.PAYCALC_ITEMS}/bulk-update/`, options),
      bulk_add: (options = {}) => mutate('post', `${ADMIN_PATHS.PAYCALC_ITEMS}/bulk-add/`, options),
      updateStatus: (options = {}) => mutate('post', `${ADMIN_PATHS.PAYCALC_ITEMS}/update-status/`, options),
    },
    PayCalcItemTemplate: {
      getInsuranceProviders: (options = {}) =>
        query<InsuranceProviderResponseVM>(
          'get',
          `${ADMIN_PATHS.PAYCALC_ITEM_TEMPLATES}/insurance-providers/`,
          {},
          options,
        ),
      getMany: (params = {}, options = {}) =>
        query<DjangoList<IPayCalcItemTemplateVM>>(
          'get',
          `${ADMIN_PATHS.PAYCALC_ITEM_TEMPLATES}/`,
          { ...params },
          options,
          {},
          QueryKeys.PayCalcItemTemplates,
        ),
      getOne: (params = {}, options = {}) =>
        query<IPayCalcItemTemplateVM>(
          'get',
          `${ADMIN_PATHS.PAYCALC_ITEM_TEMPLATES}/:paycalcitemtemplateId/`,
          { ...params },
          options,
        ),
      create: (options = {}) => mutate('post', `${ADMIN_PATHS.PAYCALC_ITEM_TEMPLATES}/`, options),
      update: (options = {}) => mutate('put', `${ADMIN_PATHS.PAYCALC_ITEM_TEMPLATES}/:paycalcitemtemplateId/`, options),
      delete: (options = {}) =>
        mutate('delete', `${ADMIN_PATHS.PAYCALC_ITEM_TEMPLATES}/:paycalcitemtemplateId/`, options),
    },

    File: {
      generate: (options = {}) =>
        mutate<EDocumentResultVM>('post', `${ADMIN_PATHS.PLANS}/:planId/documents/generate/`, options),
      getMany: (params = {}, options = {}) =>
        query<IFileVM[]>('get', `${ADMIN_PATHS.PLANS}/:planId/files/`, params, options),
    },

    Steps: {
      getOne: (params = {}, options = {}) =>
        query<IStepVM>('get', `${ADMIN_PATHS.BASE}/steps/:stepId/`, params, options),
      bulk_delete: (options = {}) => mutate('put', `${ADMIN_PATHS.BASE}/steps/bulk_delete/`, options),
      createFromTemplate: (options = {}) => mutate('post', `${ADMIN_PATHS.BASE}/steps/create-from-template/`, options),
    },
  },
  Permission: {
    getOne: (params = {}, options = {}) =>
      query<IRolePermissionVM>('get', `${ADMIN_PATHS.BASE}/permissions/:permissionId/`, params, options),
    getMany: (params = {}, options = {}) =>
      query<DjangoList<IRolePermissionVM>>('get', `${ADMIN_PATHS.BASE}/permissions/`, params, options),
    update: (options = {}) => mutate('patch', `${ADMIN_PATHS.BASE}/permissions/:permissionId/`, options),
    create: (options = {}) => mutate('post', `${ADMIN_PATHS.BASE}/permissions/`, options),
    delete: (options = {}) => mutate('delete', `${ADMIN_PATHS.BASE}/permissions/:permissionId/`, options),
  },
  User: {
    getOne: (params = {}, options = {}) => query<IUserVM>('get', `${ADMIN_PATHS.USERS}/:id/`, params, options, {}),
    getMany: (params = {}, options = {}) =>
      query<DjangoList<IUserVM>>('get', `${ADMIN_PATHS.USERS}/`, params, options, {}, QueryKeys.AdminUserMany),
    getSuperusers: (params = {}, options = {}) =>
      query<IUserVM[]>('get', `${ADMIN_PATHS.USERS}/superusers/`, params, options),
    sendWelcomeEmail: (options = {}) => mutate('post', `${ADMIN_PATHS.USERS}/welcome-email/`, options),
    addEmployee: (options = {}) => mutate('post', `${ADMIN_PATHS.USERS}/`, options),
    editEmployee: (options = {}) => mutate('put', `${ADMIN_PATHS.USERS}/:userId/`, options),
    deleteEmployee: (options = {}) => mutate('delete', `${ADMIN_PATHS.USERS}/:userId/`, options),
    deactivateEmployee: (options = {}) => mutate('patch', `${ADMIN_PATHS.USERS}/:userId/`, options),
    resetEmployeeMfa: (options = {}) => mutate('post', `${BASE_AUTH_API_PATH}/admin/user/:userId/2fa-reset/`, options),
    Profile: {
      getOne: (params = {}, options = {}) =>
        query<IProfileVM>(
          'get',
          `${ADMIN_PATHS.USERS}/:userId/profile/`,
          params,
          options,
          {},
          QueryKeys.AdminUserProfileOne,
        ),
    },
    ScheduleDays: {
      create: (options = {}) => mutate('post', `${ADMIN_PATHS.USERS}/:userId/schedule/days/`, options),
      update: (options = {}) => mutate('put', `${ADMIN_PATHS.USERS}/:userId/schedule/days/:rangeId/`, options),
      delete: (options = {}) => mutate('delete', `${ADMIN_PATHS.USERS}/:userId/schedule/days/:rangeId/`, options),
      getMany: (params = {}, options = {}) =>
        query<DjangoList<IScheduleDaysVM>>(
          'get',
          `${ADMIN_PATHS.USERS}/:userId/schedule/days/`,
          params,
          options,
          {},
          QueryKeys.ScheduleDaysGetMany,
        ),
      createBenefits: (options = {}) =>
        mutate('post', `${ADMIN_PATHS.USERS}/:userId/schedule/days/benefit-designation/`, options),
      getHistoricalDays: (params = {}, options = {}) =>
        query<IScheduleDaysVM[]>('get', `${ADMIN_PATHS.USERS}/:userId/schedule/days/historical/`, params, options),
    },
  },
  Task: {
    getMany: (params = {}, options = {}, deps = {}) =>
      query<ITaskResultVM>('get', `${ADMIN_PATHS.TASKS}/`, params, options, { ...deps }),
    create: (options = {}) => mutate('post', `${ADMIN_PATHS.TASKS}/`, options),
    update: (options = {}) => mutate('patch', `${ADMIN_PATHS.TASKS}/:taskId/`, options),
    delete: (options = {}) => mutate('delete', `${ADMIN_PATHS.TASKS}/:taskId/`, options),
    sendEmail: (options = {}) => mutate('post', `${ADMIN_PATHS.TASKS}/:taskId/send-email/`, options),
    deleteMany: (options = {}) => mutate('delete', `${ADMIN_PATHS.TASKS}/bulk_delete/`, options),
    toggleDoneMany: (options = {}) => mutate('patch', `${ADMIN_PATHS.TASKS}/bulk_toggle_done/`, options),
    bulkMoveDates: (options = {}) => mutate('patch', `${ADMIN_PATHS.TASKS}/move_due_dates/`, options),
  },
  TaskTemplate: {
    getMany: (params = {}, options = {}) =>
      query<ITaskTemplateResultVM>('get', `${ADMIN_PATHS.TASK_TEMPLATES}/`, params, options),
    create: (options = {}) => mutate('post', `${ADMIN_PATHS.TASK_TEMPLATES}/`, options),
    update: (options = {}) => mutate('patch', `${ADMIN_PATHS.TASK_TEMPLATES}/:taskTemplateId/`, options),
    delete: (options = {}) => mutate('delete', `${ADMIN_PATHS.TASK_TEMPLATES}/:taskTemplateId/`, options),
  },
  Tracking: {
    getEntitlement: (params = {}, options = {}) =>
      query<any[]>('get', `${ADMIN_PATHS.TRACKING}/entitlement/`, params, options),
    getUsage: (params = {}, options = {}) => query<any[]>('get', `${ADMIN_PATHS.TRACKING}/usage/`, params, options),
    getReport: (params = {}, options = {}) => query<any[]>('get', `${ADMIN_PATHS.TRACKING}/report/`, params, options),
  },
  StepTemplates: {
    getMany: (params = {}, options = {}) =>
      query<DjangoList<IStepTemplateVM>>(
        'get',
        `${ADMIN_PATHS.BASE}/steptemplates/`,
        params,
        options,
        {},
        QueryKeys.StepTemplateGetMany,
      ),
    getOne: (params = {}, options = {}) =>
      query<IStepTemplateVM>(
        'get',
        `${ADMIN_PATHS.BASE}/steptemplates/:templateId/`,
        params,
        options,
        {},
        QueryKeys.StepTemplateGetOne,
      ),
    create: (options = {}) =>
      mutate<IStepTemplateVM>('post', `${ADMIN_PATHS.BASE}/steptemplates/`, {
        ...options,
        isUpload: true,
      }),
    update: (options = {}) =>
      mutate<IStepTemplateVM>('put', `${ADMIN_PATHS.BASE}/steptemplates/:templateId/`, {
        ...options,
        isUpload: true,
      }),
    delete: (options = {}) => mutate('delete', `${ADMIN_PATHS.BASE}/steptemplates/:templateId/`, options),
  },
};

export default Admin;
