import { datadogLogs, LogsEvent } from '@datadog/browser-logs';
import { datadogRum, RumEvent, RumResourceEvent } from '@datadog/browser-rum';

export const initializeDatadogRum = () => {
  datadogRum.init({
    applicationId: process.env.NEXT_PUBLIC_DD_APPLICATIONID,
    clientToken: process.env.NEXT_PUBLIC_DD_CLIENTTOKEN,
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: 'datadoghq.com',
    service: 'tilt-frontend',
    env: process.env.NEXT_PUBLIC_ENV_NAME,
    version: process.env.NEXT_PUBLIC_TAG_VERSION,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 0, // Session replay sends PII to datadog
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    beforeSend(event) {
      redactResetToken(event, 'view', ['url', 'referrer']);
      redactResetToken(event, 'resource', ['url'], 'document');
      return true;
    },
  });
  datadogRum.setUser({});

  datadogLogs.init({
    clientToken: process.env.NEXT_PUBLIC_DD_CLIENTTOKEN,
    site: 'datadoghq.com',
    service: 'tilt-frontend',
    env: process.env.NEXT_PUBLIC_ENV_NAME,
    version: process.env.npm_package_version,
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    beforeSend(event: LogsEvent) {
      redactResetToken(event, 'view', ['url', 'referrer']);
      return true;
    },
  });
};

/**
 * Redacts `resetToken` from specified event properties.
 *
 * @param event - The RUM event object.
 * @param key - The key in the event to inspect (e.g., 'view' or 'resource').
 * @param fields - Array of field names to redact (e.g., ['url', 'referrer']).
 * @param resourceType - (Optional) Only redact if the resource matches this type.
 */
const redactResetToken = (
  event: RumEvent | RumResourceEvent | LogsEvent,
  key: 'view' | 'resource',
  fields: string[],
  resourceType?: string,
) => {
  const target = (event as any)[key];
  if (!target) return;

  const shouldRedact = !resourceType || (key === 'resource' && target.type === resourceType);

  if (shouldRedact) {
    fields.forEach((field) => {
      if (target[field]?.includes('resetToken=')) {
        target[field] = target[field].replace(/resetToken=[^&]*/, 'resetToken=REDACTED');
      }
    });
  }
};
