export const EE_BIRTH_DETAILS_TIME_OFF_MODAL_SUBMIT_ID = 'EE-Birth-Details-Time-Off-Modal-Submit-Button';
export const EE_BIRTH_DETAILS_TIME_OFF_MODAL_CANCEL_ID = 'EE-Birth-Details-Time-Off-Modal-Cancel-Button';

//EE PDP View IDs
export const EE_PDP_TIME_OFF_CALENDAR_TIME_OFF_MODAL_SUBMIT_BUTTON_ID =
  'EE-PDP-Time-Off-Calendar-Time-Off-Modal-Submit-Button' as const;
export const EE_PDP_TIME_OFF_CALENDAR_TIME_OFF_MODAL_CANCEL_BUTTON_ID =
  'EE-PDP-Time-Off-Calendar-Time-Off-Modal-Cancel-Button' as const;

//Birth Details
export const EE_BIRTH_DETAILS_CLOSE_MODAL_BUTTON_ID = 'EE-Birth-Details-Close-Modal-Button' as const;
export const EE_BIRTH_DETAILS_EDIT_BUTTON_ID = 'EE-Birth-Details-Edit-Button' as const;
export const EE_BIRTH_DETAILS_BABY_WAS_BORN_BUTTON_ID = 'EE-Birth-Details-Baby-Was-Born-Button' as const;
export const EE_BIRTH_DETAILS_STILL_WAITING_BUTTON_ID = 'EE-Birth-Details-Still-Waiting-Button' as const;
export const EE_BIRTH_DETAILS_FORM_SUBMIT_ID = 'EE-Birth-Details-Form-Submit-Button' as const;
export const EE_BIRTH_DETAILS_FORM_CANCEL_ID = 'EE-Birth-Details-Form-Cancel-Button' as const;
export const EE_BIRTH_DETAILS_EDIT_LEAVE_TIME_CHANGES_NEEDED_BUTTON_ID =
  'EE-Birth-Details-Edit-Leave-Time-Changes-Needed-Button' as const;
export const EE_BIRTH_DETAILS_EDIT_LEAVE_TIME_BUTTON_ID = 'EE-Birth-Details-Edit-Leave-Time-Button' as const;

//Dashboard Page
export const EE_MY_LEAVES_DASHBOARD_REQUEST_NEW_LEAVE_BUTTON_ID =
  'EE-My-Leaves-Dashboard-Request-New-Leave-Button' as const;
export const EE_MY_LEAVES_DASHBOARD_KNOWLEDGE_CENTER_REQUEST_A_LEAVE_ID =
  'EE-My-Leaves-Dashboard-Knowledge-Center-Request-A-Leave-Card' as const;

// Knowledge Center Experiment IDs
export const KNOWLEDGE_CENTER_EXP_ARTICLES_FOR_EMPLOYEES_TAKING_A_LEAVE_OF_ABSENCE_ARTICLES_ID =
  'EE-Knowledge-Center-articles-for-employees-taking-a-leave-of-absence' as const;
export const KNOWLEDGE_CENTER_EXP_RESOURCES_AND_INFORMATION_RELATED_TO_FMLA_ARTICLES_ID =
  'EE-Knowledge-Center-resources-and-information-related-to-fmla' as const;
export const KNOWLEDGE_CENTER_EXP_PAID_AND_UNPAID_STATE_BENEFIT_PROGRAMS_ARTICLES_ID =
  'EE-Knowledge-Center-paid-and-unpaid-state-benefit-programs' as const;
export const KNOWLEDGE_CENTER_EXP_INTERMITTENT_LEAVES_OF_ABSENCE_ARTICLES_ID =
  'EE-Knowledge-Center-intermittent-leaves-of-absence' as const;
export const EE_PDP_KNOWLEDGE_CENTER_HELP_CENTER_BUTTON_ID = 'EE-PDP-Knowledge-Center-Help-Center-Button' as const;
export const EE_MY_LEAVES_DASHBOARD_KNOWLEDGE_CENTER_HELP_CENTER_BUTTON_ID =
  'EE-My-Leaves-Dashboard-Knowledge-Center-Help-Center-Button' as const;
export const EE_MY_LEAVES_DASHBOARD_KNOWLEDGE_CENTER_REQUEST_A_LEAVE_BUTTON_ID =
  'EE-My-Leaves-Dashboard-Knowledge-Center-Request-A-Leave-Button' as const;
export const EE_KNOWLEDGE_CENTER_REQUEST_A_LEAVE_WHAT_TO_KNOW_BEFORE_ENTERING_A_LEAVE_IN_TILT_ARTICLES_ID =
  'EE-Knowledge-Center-Request-A-Leave-What-To-Know-Before-Entering-A-Leave-In-Tilt' as const;

// Leave Form Page
export const EE_LEAVE_FORM_PAGE_SUBMIT_LEAVE_BUTTON_ID = 'EE-Leave-Form-Page-Submit-Button' as const;
export const EE_LEAVE_FORM_PAGE_UPDATE_LEAVE_BUTTON_ID = 'EE-Leave-Form-Page-Update-Leave-Button' as const;
