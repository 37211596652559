export const FLAG_ENABLE_FMLA_BALANCE_CHECK = 'enableFmlaBalanceCheck';
export const FLAG_HR_PAYCALC_VERSION_HISTORY = 'hrPaycalcVersionHistory';
export const FLAG_ADD_RIPPLING_PAYROLL_COLUMNS = 'addRipplingPayrollColumns';
export const FLAG_USER_RIPPLING_PAY_SCHEDULE_INGESTION = 'userRipplingPayScheduleIngestion';
export const FLAG_ENABLE_VARIABLE_SCHEDULE_TRACKING = 'enableVariableScheduleTracking';
export const FLAG_ENABLE_VARIABLE_SCHEDULE_DATA_COLLECTION = 'enableVariableScheduleDataCollection';
export const FLAG_ALLOW_FOR_IRREGULAR_SCHEDULE = 'allowForIrregularSchedule';
export const FLAG_ENABLE_NON_MEDCERT_OR_EOB_DOCUMENT_STATUS_FLOW = 'enableNonMedCertOrEoBDocumentFlow';
export const FLAG_ENABLE_EOB_DOCUMENT_FLOW = 'enableEoBDocumentFlow';
export const FLAG_INTEGRATION_SYSTEM = 'integrationSystem';
export const FLAG_ENABLE_BENEFIT_STATUSES_NON_ADMIN = 'enableBenefitStatusesNonAdmin';
export const FLAG_ENABLE_BULK_ADD_BENEFITS = 'enableBulkAddBenefits';
export const FLAG_ENABLE_SALARY_ADJUSTMENTS = 'enableSalaryAdjustments';
export const FLAG_ENABLE_UI_CHANGES_VARIABLE_SCHEDULES = 'enableUIChangesVariableSchedules';
export const FLAG_SUPPORT_SFTP_PORT_22 = 'supportSftpPort22';
export const FLAG_ENABLE_FMLA_IN_BKE = 'enableFmlaInBKE';
export const FLAG_ENABLE_DENIALS_ADMIN = 'enableDenialsAdmin';
export const FLAG_COMPANY_DENIAL_STATUSES = 'companyDenialStatuses';
export const FLAG_USE_BENEFIT_STATUS_TABLE = 'useBenefitStatusTable';
export const FLAG_NEW_API_ROUTER = 'newApiRouter';
export const FLAG_ENABLE_COMPANY_STATUSES = 'enableCompanyStatuses';
export const FLAG_HR_ARCHIVE_UNARCHIVE_LEAVES = 'hrArchiveUnarchiveLeaves';
export const FLAG_ENABLE_LSM_ACCESS_CONTROLS = 'enableLsmAccessControls';
export const FLAG_ENABLE_HR_ACCESS_CONTROLS = 'enableHrAccessControls';
export const FLAG_ENABLE_KNOWLEDGE_CENTER = 'enableKnowledgeCenter';
export const FLAG_ENABLE_DISENGAGED_EMPLOYEES_COMPANY = 'enableDisengagedEmployeesCompany';
