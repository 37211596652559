import { ICompanyHRISImportResponse } from '@screens/Shared/CompanyUploadFileModal/CompanyUploadFileModal';
import {
  DjangoList,
  ICalendarVM,
  ICohortVM,
  ICompanyVM,
  IHolidayVM,
  ICsvPayrollReportVM,
  IFileVM,
  IHrIntegrationTestResult,
  IIntegrationVM,
  ILeaveFormDataVM,
  ILeaveHRVM,
  INotesResultVM,
  IPayCalcCurrentPayPeriod,
  IPayCalcVM,
  IPerLeaveBenefitUsage,
  IPlanMonthlyOverviewVM,
  IPlanStageOverviewVM,
  IPlanVM,
  IPolicyVM,
  IProfileVM,
  IScheduleDaysVM,
  ISftpUser,
  IUserLeaveVM,
  IUserVM,
  SyncStatus,
} from '@types';
import { IAction, IDocument, IDocumentType } from '@constants/types';
import { UseMutationResult } from '@tanstack/react-query';

import { BASE_API_PATH } from './constants';
import QueryKeys from './queryKeys';
import { mutate, query } from './request-lib';

const HR_PATHS = {
  BASE: `${BASE_API_PATH}/hr`,
  CALENDARS: `${BASE_API_PATH}/calendars/hr`,
  DOCUMENTS: `${BASE_API_PATH}/documents/hr`,
  INTEGRATIONS: `${BASE_API_PATH}/integrations/hr`,
  ACTIVITIES: `${BASE_API_PATH}/activities/hr`,
  COHORTS: `${BASE_API_PATH}/cohorts/hr`,
};

const HR = {
  Calendar: {
    getMany: (params = {}, options = {}) =>
      query<DjangoList<ICalendarVM>>('get', `${HR_PATHS.CALENDARS}/calendars/`, params, options),
    create: (options = {}) => mutate('post', `${HR_PATHS.CALENDARS}/calendars/`, options),
    update: (options = {}) => mutate('patch', `${HR_PATHS.CALENDARS}/calendars/:calendarId/`, options),
    getOne: (params = {}, options = {}) =>
      query<ICalendarVM>('get', `${HR_PATHS.CALENDARS}/calendars/:calendarId/`, params, {
        cacheTime: 0,
        ...options,
      }),
    delete: (options = {}) => mutate('delete', `${HR_PATHS.CALENDARS}/calendars/:calendarId/`, options),
    getCurrentPayPeriods: (params = {}, options = {}) =>
      query<IPayCalcCurrentPayPeriod[]>('get', `${HR_PATHS.CALENDARS}/calendars/current-pay-periods/`, params, {
        cacheTime: 0,
        ...options,
      }),
    closeMostRecentPayPeriod: (options = {}) =>
      mutate('patch', `${HR_PATHS.CALENDARS}/calendars/:calendarId/close-most-recent-payperiod/`, options),
    Holidays: {
      getMany: (params = {}, options = {}) =>
        query<DjangoList<IHolidayVM>>(
          'get',
          `${HR_PATHS.CALENDARS}/holidays/`,
          params,
          options,
          {},
          QueryKeys.HolidayGetMany,
        ),
      create: (options = {}) => mutate('post', `${HR_PATHS.CALENDARS}/holidays/`, options),
      update: (options = {}) => mutate('patch', `${HR_PATHS.CALENDARS}/holidays/:holidayId/`, options),
      delete: (options = {}) => mutate('delete', `${HR_PATHS.CALENDARS}/holidays/:holidayId/`, options),
    },
  },
  Cohort: {
    getMany: (params = {}, options = {}) =>
      query<DjangoList<ICohortVM>>('get', `${HR_PATHS.COHORTS}/cohorts/`, params, options, {}, QueryKeys.CohortGetMany),
    getOne: (params = {}, options = {}) =>
      query<ICohortVM>('get', `${HR_PATHS.COHORTS}/cohorts/:cohortId/`, params, options),
    create: (options = {}) => mutate('post', `${HR_PATHS.COHORTS}/cohorts/`, options),
    update: (options = {}) => mutate('patch', `${HR_PATHS.COHORTS}/cohorts/:cohortId/`, options),
    delete: (options = {}) => mutate('delete', `${HR_PATHS.COHORTS}/cohorts/:cohortId/`, options),
  },
  Company: {
    getOne: (params = {}, options = {}) =>
      query<ICompanyVM>('get', `${HR_PATHS.BASE}/company/:companyId/`, params, options),
    partialUpdate: (options = {}) =>
      mutate<ICompanyVM>('patch', `${HR_PATHS.BASE}/company/:companyId/`, {
        isUpload: true,
        ...options,
      }),
    Holidays: {
      getMany: (params = {}, options = {}) =>
        query<DjangoList<IHolidayVM>>('get', `${HR_PATHS.CALENDARS}/holidays/`, params, options),
    },
    importCsv: (options = {}): UseMutationResult<ICompanyHRISImportResponse> =>
      mutate('post', `${HR_PATHS.BASE}/company/import-csv/`, { isUpload: true, ...options }),
    leaveExportCsv: (params = {}, options = {}) =>
      query('get', `${HR_PATHS.BASE}/company/export-leaves/`, params, {
        enabled: false,
        ...options,
      }),
    changeReportExportCsv: (params = {}, options = {}) =>
      query('get', `${HR_PATHS.BASE}/company/export-change-report/`, params, {
        enabled: false,
        ...options,
      }),
    testIntegration: (options = {}): UseMutationResult<IHrIntegrationTestResult[]> =>
      mutate('post', `${HR_PATHS.BASE}/company/:companyId/test-integration/`, options),
    getIntegration: (params = {}, options = {}) =>
      query('get', `${HR_PATHS.BASE}/company/integration/`, params, {
        enabled: false,
        ...options,
      }),
    DocumentTypes: {
      getMany: (params = {}, options = {}) =>
        query<DjangoList<IDocumentType>>('get', `${HR_PATHS.DOCUMENTS}/document-types/`, params, options),
    },
  },
  Documents: {
    getMany: (params = {}, options = {}) =>
      query<DjangoList<IDocument>>('get', `${HR_PATHS.DOCUMENTS}/documents/`, params, options),
    update: (options = {}) =>
      mutate('patch', `${HR_PATHS.DOCUMENTS}/documents/:documentId/`, { ...options, isUpload: true, retry: false }),
    create: (options = {}) => mutate('post', `${HR_PATHS.DOCUMENTS}/documents/`, { ...options, isUpload: true }),
  },
  DocumentRequest: {
    getMany: (params = {}, options = {}) =>
      query<DjangoList<IDocument>>('get', `${HR_PATHS.DOCUMENTS}/document-requests/`, params, options),
  },
  File: {
    getMany: (params = {}, options = {}) =>
      query<IFileVM[]>('get', `${BASE_API_PATH}/legacy/files/uploads/`, params, options),
  },
  Integration: {
    create: (options = {}) => mutate<IIntegrationVM>('post', `${HR_PATHS.INTEGRATIONS}/integrations/`, options),
    delete: (options = {}) =>
      mutate<IIntegrationVM>('delete', `${HR_PATHS.INTEGRATIONS}/integrations/:integrationId/`, options),
    getProviders: (params = {}, options = {}) =>
      query<string[]>('get', `${HR_PATHS.INTEGRATIONS}/integrations/providers/`, params, options),
    getMany: (params = {}, options = {}) =>
      query<DjangoList<IIntegrationVM>>(
        'get',
        `${HR_PATHS.INTEGRATIONS}/integrations/`,
        params,
        options,
        {},
        QueryKeys.IntegrationGetMany,
      ),
    syncPayHistory: (options = {}) =>
      mutate<IIntegrationVM>('post', `${HR_PATHS.INTEGRATIONS}/sync/pay-history/?leave_id=:leaveId`, options),
    resync: (options = {}) => mutate('post', `${HR_PATHS.INTEGRATIONS}/sync/all/`, options),
    test: (options = {}) => mutate('post', `${HR_PATHS.INTEGRATIONS}/integrations/test/`, options),
    update: (options = {}) =>
      mutate<IIntegrationVM>('patch', `${HR_PATHS.INTEGRATIONS}/integrations/:integrationId/`, options),
    getStatus: (params = {}, options = {}) =>
      query<SyncStatus>('get', `${HR_PATHS.INTEGRATIONS}/integrations/:integrationId/status/`, params, options),
  },
  Leave: {
    create: (options = {}) => mutate('post', `${HR_PATHS.BASE}/leaves/`, { isUpload: true, ...options }),
    getOne: (params = {}, options = {}) =>
      query<IUserLeaveVM>('get', `${HR_PATHS.BASE}/leaves/:leaveId/`, params, {
        cacheTime: 0,
        ...options,
      }),
    getMany: (params = {}, options = {}) =>
      query<DjangoList<ILeaveHRVM>>('get', `${HR_PATHS.BASE}/leaves/`, params, options),
    update: (options = {}) => mutate('patch', `${HR_PATHS.BASE}/leaves/:leaveId/`, { isUpload: true, ...options }),
    getInfo: (params = {}, options = {}) =>
      query<ILeaveHRVM>('get', `${HR_PATHS.BASE}/leaves/:leaveUuid/info/`, params, options),
    archive: (options = {}) => mutate('post', `${HR_PATHS.BASE}/leaves/:leaveId/archive/`, options),
    unarchive: (options = {}) => mutate('post', `${HR_PATHS.BASE}/leaves/:leaveId/unarchive/`, options),
    Benefit: {
      getMany: (params = {}, options = {}) =>
        query<IPerLeaveBenefitUsage[]>('get', `${HR_PATHS.BASE}/leaves/:leaveId/benefits/`, params, options),
    },
    Form: {
      getNew: (params = {}, options = {}) =>
        query<ILeaveFormDataVM>('get', `${HR_PATHS.BASE}/leaves/form/`, params, options),
      getOne: (params = {}, options = {}) =>
        query<ILeaveFormDataVM>('get', `${HR_PATHS.BASE}/leaves/:leaveId/form/`, params, options),
    },
    HistoricalProfile: {
      getOne: (params = {}, options = {}) =>
        query<IProfileVM>('get', `${HR_PATHS.BASE}/leaves/:leaveId/historical-profile/`, params, options),
    },
    ScheduleDays: {
      getHistoricalDays: (params = {}, options = {}) =>
        query<IScheduleDaysVM[]>('get', `${HR_PATHS.BASE}/leaves/:leaveId/schedule-days/historical/`, params, options),
    },
  },
  Note: {
    getMany: (params = {}, options = {}) => query<INotesResultVM>('get', `${HR_PATHS.BASE}/notes/`, params, options),
    markRead: (options = {}) => mutate('post', `${HR_PATHS.BASE}/notes/:noteId/read/`, options),
  },
  Paycalc: {
    getMany: (params = {}, options = {}) =>
      query<DjangoList<IPayCalcVM>>('get', `${HR_PATHS.BASE}/paycalcs/`, params, options),
  },
  Plan: {
    getMany: (params = {}, options = {}) =>
      query<DjangoList<IPlanVM[]>>('get', `${HR_PATHS.BASE}/plans/`, params, options),
    getOne: (params = {}, options = {}) => query<IPlanVM>('get', `${HR_PATHS.BASE}/plans/:planId/`, params, options),
    getStageOverview: (params = {}, options = {}) =>
      query<IPlanStageOverviewVM>('get', `${HR_PATHS.BASE}/plans/overview/stage/`, params, options),
    getMonthlyOverview: (params = {}, options = {}) =>
      query<IPlanMonthlyOverviewVM>('get', `${HR_PATHS.BASE}/plans/overview/monthly/`, params, options),
  },
  Policy: {
    getOnly: (params = {}, options = {}) =>
      query<IPolicyVM>('get', `${HR_PATHS.BASE}/policies/`, params, options, {}, QueryKeys.OnboardingPolicy),
    update: (options = {}) => mutate('put', `${HR_PATHS.BASE}/policies/:policyId/`, { isUpload: true, ...options }),
    create: (options = {}) => mutate('post', `${HR_PATHS.BASE}/policies/`, { isUpload: true, ...options }),
    Form: {
      get: (params = {}, options = {}) =>
        query<ILeaveFormDataVM>('get', `${HR_PATHS.BASE}/policies/form/`, params, options),
    },
    OnboardingSettingsForm: {
      get: (params = {}, options = {}) =>
        query<ILeaveFormDataVM>('get', `${HR_PATHS.BASE}/policies/company-settings-form/`, params, options),
    },
  },
  User: {
    create: (options = {}) => mutate<IUserVM>('post', `${HR_PATHS.BASE}/users/`, options),
    update: (options = {}) => mutate<IUserVM>('patch', `${HR_PATHS.BASE}/users/:userId/`, options),
    getOne: (params = {}, options = {}) => query<IUserVM>('get', `${HR_PATHS.BASE}/users/:id/`, params, options),
    getMany: (params = {}, options = {}) =>
      query<DjangoList<IUserVM>>('get', `${HR_PATHS.BASE}/users/`, params, options),
    sendWelcomeEmail: (options = {}) => mutate('post', `${HR_PATHS.BASE}/users/welcome-email/`, options),
    Profile: {
      getOne: (params = {}, options = {}) =>
        query<IProfileVM>('get', `${HR_PATHS.BASE}/users/:userId/profile/`, params, options),
      getEePayHistory: (params = {}, options = {}) =>
        query<IProfileVM>('get', `${HR_PATHS.BASE}/users/:userId/pay-history/`, params, options),
    },
    Sftp: {
      getSftpUser: (params = {}, options = {}) =>
        query<ISftpUser>('get', `${HR_PATHS.BASE}/company/sftp/`, params, options),
      createSftpUser: (options = {}): UseMutationResult<ISftpUser> =>
        mutate('post', `${HR_PATHS.BASE}/company/sftp/`, options),
      updateSftpPassword: (options = {}): UseMutationResult<ISftpUser> =>
        mutate('patch', `${HR_PATHS.BASE}/company/sftp/`, options),
      deleteSftpUser: (options = {}): UseMutationResult<ISftpUser> =>
        mutate('delete', `${HR_PATHS.BASE}/company/sftp/`, options),
    },
  },
  Tracking: {
    getEntitlement: (params = {}, options = {}) =>
      query<any[]>('get', `${BASE_API_PATH}/tracking/hr/entitlement/`, params, options),
    getReport: (params = {}, options = {}) =>
      query<any[]>('get', `${BASE_API_PATH}/tracking/hr/report/`, params, options),
  },
  Report: {
    Payroll: {
      getAllCalendarsReport: (params = {}, options = {}) =>
        query<ICsvPayrollReportVM[]>('get', `${HR_PATHS.BASE}/payroll/report-all-cals/`, params, options),
      getOneCalendarReport: (params = {}, options = {}) =>
        query<ICsvPayrollReportVM | string>('get', `${HR_PATHS.BASE}/payroll/report-one-cal/`, params, options),
    },
  },
  Action: {
    getMany: (params = {}, options = {}) =>
      query<DjangoList<IAction>>('get', `${HR_PATHS.ACTIVITIES}/feed/`, params, options),
    getOne: (params = {}, options = {}) =>
      query<DjangoList<IAction>>('get', `${HR_PATHS.ACTIVITIES}/feed/:actionId/`, params, options),
  },
};

export default HR;
