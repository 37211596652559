import { ICompanyVM, IProfileVM } from '@types';
import cx from 'classnames';
import { useIdentity } from 'contexts/auth-context';
import React, { useEffect } from 'react';

import styles from './AllComponentStyles.module.scss';
import Badge, { BadgeColor } from '@components/v2/Badge/Badge';

declare global {
  interface Window {
    Kustomer: any;
  }
}

export const PageTitle = (props: { title: any; children?: any }) => (
  <div className={styles.header}>
    <div className={styles.titleContainer}>
      <h2 className={styles.title}>{props.title}</h2>
    </div>
    <div className={styles.btnsContainer}>{props.children}</div>
  </div>
);

interface IProps {
  title: string;
  employee: IProfileVM;
  children?: React.FC | any;
  company?: ICompanyVM;
}

export const PlanPageTitle = ({ title, employee, company, children }: IProps) => (
  <div className={cx(styles.header, styles.noPadding)}>
    <div className={styles.planTitleContainer}>
      {employee?.jobTitle && <h3 className={styles.jobTitle}>{employee.jobTitle}</h3>}
      <div className={styles.titleBlock}>
        <h2 className={styles.title}>{title}</h2>
        {company?.enforceFmla50And75Rule && <Badge color={BadgeColor.SOLID_PUMPKIN}>50/75 applies</Badge>}
        {company?.paysOnHolidays && (
          <Badge color={BadgeColor.SOLID_GREEN} css={{ '@xl': { marginInlineStart: '$4' } }}>
            Holidays Paid
          </Badge>
        )}
      </div>
    </div>
    <div className={styles.btnsContainer}>{children}</div>
  </div>
);

export const KustomerWrapper = ({ children }: { children: any }) => {
  const {
    state: { me },
  } = useIdentity();

  useEffect(() => {
    if (!process.env.NEXT_PUBLIC_KUSTOMER_API_KEY) return;
    else if (!me) {
      // TODO stop never gets called here?
      window.removeEventListener('kustomerLoaded', () => window.Kustomer.stop());
      return;
    }

    // Since we use GTag manager, we follow these instructions
    // https://developer.kustomer.com/chat-sdk/v2.0-Web/docs/load-chat-asynchronously
    // To load it asynchronously
    window.addEventListener('kustomerLoaded', function () {
      window.Kustomer.start({
        chatIconSize: { height: 45, width: 45 },
        chatIconPosition: { verticalPadding: 45 },
      });
    });

    const script = document.createElement('script');
    script.src = 'https://cdn.kustomerapp.com/chat-web/widget.js';
    script.id = 'kustomer-api-key';
    script.setAttribute('data-kustomer-api-key', process.env.NEXT_PUBLIC_KUSTOMER_API_KEY);
    window.document.body.appendChild(script);

    return () => {
      window.removeEventListener('kustomerLoaded', () => window.Kustomer.stop());
    };
  }, [me]);
  return <>{children}</>;
};
