import { getAccessToken, saveAccessToken, saveImpersonateAccessToken } from '@utils/auth-tokens';
import { useIdentity } from 'contexts/auth-context';
import { datadogRum } from '@datadog/browser-rum';
import jwtDecode from 'jwt-decode';
import type { NextPage } from 'next';
import { useRouter } from 'next/router';
import type { ReactElement, ReactNode } from 'react';
import React, { useEffect } from 'react';
import { getHref, getLoginRedirectUrl } from 'router';

// https://nextjs.org/docs/basic-features/layouts#with-typescript
export type NextPageWithLayout<P = any, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

const shouldBeLoggedIn = (BaseComponent) => {
  const ShouldBeLoggedInComponent = (props) => {
    const router = useRouter();
    const {
      state: { me },
    } = useIdentity();
    const resetUserLoginStatus = () => {
      saveAccessToken('');
      saveImpersonateAccessToken('');
      const { path, backTo } = getLoginRedirectUrl();
      router && router.push(getHref(`${path}${backTo}`), `${path}${backTo}`);
    };
    useEffect(() => {
      const redirectToLogin = () => {
        const accessToken = getAccessToken();
        if (accessToken) {
          try {
            const { exp, needs2fa, has2fa } = jwtDecode(accessToken) as any;
            if (needs2fa && !has2fa) {
              resetUserLoginStatus();
            }
            if (!me && exp * 1000 <= Date.now()) {
              datadogRum.setUser({});
              const { path, backTo } = getLoginRedirectUrl();
              router && router.push(getHref(`${path}${backTo}`), `${path}${backTo}`);
            } else if (me) {
              datadogRum.setUser({ id: String(me.id), email: me.email });
            }
          } catch (err) {
            resetUserLoginStatus();
          }
        } else {
          if (me) {
            return datadogRum.setUser({ id: String(me.id), email: me.email });
          }
          datadogRum.setUser(undefined);
          const { path, backTo } = getLoginRedirectUrl();
          router && router.push(getHref(`${path}${backTo}`), `${path}${backTo}`);
        }
      };

      redirectToLogin();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [me]); // exclude router to avoid infinite re-render

    if (!me) {
      return null;
    }

    return React.createElement(BaseComponent, { me, router, ...props });
  };

  return ShouldBeLoggedInComponent as NextPageWithLayout;
};

export default shouldBeLoggedIn;
