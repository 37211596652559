import { IBenefitResultVM, IPlanAdminVM } from '@types';
import React, { createContext, useContext, useReducer } from 'react';
import { IFormattedBenefit } from '@screens/Admin/PlanDetailsPage/Sidebar/PlanBenefitsCard/EligibleBenefitsSection/EligibleBenefitsSection';

export enum NavActions {
  SET_PLAN = 'SET_PLAN',
  SET_OPEN = 'SET_OPEN',
  CLOSE = 'CLOSE',
  TOGGLE_BIRTH_DETAILS = 'TOGGLE_BIRTH_DETAILS',
  SET_PROCESSED_BENEFITS = 'SET_PROCESSED_BENEFITS',
}

export enum ContentType {
  PROFILE = 'PROFILE',
  BIRTH_DETAILS = 'BIRTH_DETAILS',
  BENEFITS = 'BENEFITS',
  COMPANY_DETAILS = 'COMPANY_DETAILS',
}

const defaultState = {
  plan: null,
  open: true,
  type: ContentType.PROFILE,
  showBirthDetails: true,
  benefits: [],
  formattedBenefits: [],
  holidays: [],
};

type Action = { type: NavActions; payload?: any };
type Dispatch = (action: Action) => void;
export type State = {
  plan: IPlanAdminVM;
  open: boolean;
  type: ContentType;
  showBirthDetails: boolean;
  benefits: IBenefitResultVM[];
  formattedBenefits?: IFormattedBenefit[];
};
type NavigationProviderProps = { children: React.ReactNode; initialState?: State };

export const NavigationContext = createContext<{ state: State; dispatch: Dispatch } | undefined>(undefined);

export function navReducer(state: State, action) {
  switch (action.type) {
    case NavActions.SET_PLAN: {
      if (!action.payload) {
        return { ...defaultState, open: window.localStorage.getItem('right-flyout') === 'false' ? false : true };
      }
      const payload = { ...action.payload, ...action.payload.plan };
      delete payload['plan'];
      return {
        ...state,
        plan: payload,
        showBirthDetails: payload?.leave?.displayBirthDetails || state.showBirthDetails,
      };
    }
    case NavActions.SET_OPEN: {
      const newState = {
        ...state,
        open: state.type === action.payload.type ? !state.open : true,
        type: action.payload.type,
      };
      const flyoutPreference = window.localStorage.getItem('right-flyout');
      if (!flyoutPreference) {
        window.localStorage.setItem('right-flyout', newState.open.toString());
      } else if (flyoutPreference === 'false' && newState.open) {
        window.localStorage.setItem('right-flyout', 'true');
      } else if (flyoutPreference === 'true' && !newState.open) {
        window.localStorage.setItem('right-flyout', 'false');
      }
      return newState;
    }
    case NavActions.CLOSE: {
      window.localStorage.setItem('right-flyout', 'false');
      return {
        ...state,
        open: false,
      };
    }
    case NavActions.TOGGLE_BIRTH_DETAILS: {
      return {
        ...state,
        showBirthDetails: !state.showBirthDetails,
      };
    }
    case NavActions.SET_PROCESSED_BENEFITS: {
      return {
        ...state,
        benefits: action.payload?.benefits || state.benefits,
        formattedBenefits: action.payload?.formatted || state.formattedBenefits,
      };
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function NavigationProvider({ children, initialState }: NavigationProviderProps) {
  const [state, dispatch] = useReducer(
    navReducer,
    initialState || { ...defaultState, open: window.localStorage.getItem('right-flyout') === 'false' ? false : true },
  );
  const value = { state, dispatch };
  return <NavigationContext.Provider value={value}>{children}</NavigationContext.Provider>;
}

function useNavigationData() {
  const context = useContext(NavigationContext);
  if (context === undefined) {
    throw new Error('useNavigationData must be used within a NavigationProvider');
  }
  return context;
}

export { NavigationProvider, useNavigationData };
