enum QueryKeys {
  AdminPlanOne = 'ADMIN_PLAN_ONE',
  AdminPlanMany = 'ADMIN_PLAN_MANY',
  AdminUserMany = 'ADMIN_USER_MANY',
  AdminUserProfileOne = 'ADMIN_USER_PROFILE_ONE',
  UserAuthMe = 'USER_AUTH_ME',
  AdminPlanPaycalcMany = 'ADMIN_PLAN_PAYCALC_MANY',
  BenefitResults = 'BENEFIT_RESULTS',
  EePlanOne = 'EE_PLAN_ONE',
  OnboardingPolicy = 'ONBOARDING_POLICY',
  PayCalcItemTemplates = 'ADMIN_PAYCALC_ITEM_TEMPLATES_MANY',
  AdminDocumentsMany = 'ADMIN_DOCUMENTS_MANY',
  CoreStepTemplateGetMany = 'CORE_STEP_TEMPLATES_MANY',
  StepTemplateGetMany = 'STEP_TEMPLATES_MANY',
  StepTemplateGetOne = 'STEP_TEMPLATES_ONE',
  CoreStepTemplateGetOne = 'CORE_STEP_TEMPLATES_ONE',
  IntegrationGetMany = 'INTEGRATIONS_MANY',
  ScheduleDaysGetMany = 'SCHEDULE_DAYS_GET_MANY',
  CohortGetMany = 'COHORTS_GET_MANY',
  HolidayGetMany = 'HOLIDAYS_GET_MANY',
}

export default QueryKeys;
