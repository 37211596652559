/* eslint-disable no-console */
import 'rc-dropdown/assets/index.css';
import 'rc-tooltip/assets/bootstrap_white.css';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'url-search-params-polyfill';
import '../components/Accordion/Accordion.scss';
import '../components/PayCalcTimeline/PayCalcTimeline.scss';
import '../scss/calendar.scss';
import '../scss/main.scss';
import '../scss/nprogress.scss';
import '../scss/rc-dropdown.scss';
import '../scss/rc-tooltip.scss';
import '../tokens.css';

import React, { useEffect } from 'react';
import { initializeDatadogRum } from 'Datadog';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode';
import Head from 'next/head';
import Router, { useRouter } from 'next/router';
import NProgress from 'nprogress';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Provider } from 'react-redux';
import { getLoginRedirectUrl } from 'router';
import createStore from 'store';
import { ThemeProvider } from '@ourtilt/tilt-ui-components';

import useApi from '@api/transportLayer';
import Toast from '@components/v2/Toast';
import { GoogleOAuthProvider } from '@react-oauth/google';
import {
  get2faIgnorePage,
  getAccessToken,
  getDeviceUID,
  saveAccessToken,
  saveImpersonateAccessToken,
  set2faIgnorePage,
} from '@utils/auth-tokens';
import { AuthProvider, SET_ME, useIdentity } from 'contexts/auth-context';
import { NavigationProvider } from 'contexts/right-flyout-context';
import { UUIDProvider } from 'contexts/uuid-context';
import { useAppDispatch } from 'hooks/reduxHooks';
import { KustomerWrapper } from 'layouts/Pages';
import PendoInitializer from '../lib/pendo/pendo-initializer';
import PlanProvider from 'contexts/plan-context';

Router.events.on('routeChangeStart', (url, { shallow }) => {
  !shallow && NProgress.start();
});
Router.events.on('routeChangeComplete', (url: string) => {
  // Send custom 'pageview' event to GTM that will update the GA info
  (window as any)?.dataLayer?.push({
    event: 'pageview',
    page: url,
  });
  NProgress.done();
  window.scrollTo(0, 0);
});
Router.events.on('routeChangeError', () => NProgress.done());

const store = createStore(null);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

// TODO(redux): remove this once we no longer need to access the redux store in transportLayer.
// Also, this is "Safe" because we cannot set reduxStore into global state until SSR completes.
function SafeSetReduxDispatchOnWindow() {
  const client = typeof window !== 'undefined';
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (client) {
      (window as any).__reduxDispatch__ = dispatch;
    }
  }, [client, dispatch]);
  return null;
}

function SafeIdentify({ children }: any) {
  const [identified, setIdentified] = React.useState(false);
  const router = useRouter();
  const { dispatch: authDispatch } = useIdentity();
  const dispatch = useAppDispatch();

  // ! No requests needing a token should be made here until the access token
  // ! has been updated from the query params (we may have a stale token in
  // ! local storage from a previous session)

  const { refetch: getMe } = useApi.Auth.getMe(
    {},
    {
      enabled: false,
      onSuccess: (data) => {
        authDispatch({ type: SET_ME, payload: data });
      },
      onError: () => {
        authDispatch({ type: SET_ME, payload: null });
      },
    },
  );
  const { mutateAsync: refreshToken } = useApi.Auth.refreshToken();

  useEffect(() => {
    function identifyUser() {
      try {
        // Next does not hydrate router.query initially so we should wait
        // so we grab an updated impersonate token if it exists
        // Ref: https://nextjs.org/docs/routing/dynamic-routes#caveats
        if (!router.isReady) return;

        const impersonateToken = router.query.impersonateToken;
        if (impersonateToken) {
          saveImpersonateAccessToken(impersonateToken);
          router.replace('/', undefined, { shallow: true });
        }

        // handle sso token from redirect
        const ssoToken = Cookies.get('access_token');
        if (ssoToken) saveAccessToken(ssoToken);

        const accessToken = impersonateToken || ssoToken || getAccessToken();
        if (accessToken) {
          const TOKEN_REFRESH_SEC = 60 * 60 * 2;
          const { iat, needs2fa, has2fa, impersonating } = jwt_decode(accessToken) as any;

          if ((needs2fa && !has2fa) || (get2faIgnorePage() && !impersonating)) {
            setIdentified(true);
            return;
          }

          if (impersonating) {
            set2faIgnorePage('');
          }

          const now = Math.floor(Date.now() / 1000);
          if (now - iat >= TOKEN_REFRESH_SEC) {
            refreshToken({ device_uuid: getDeviceUID() });
          }
          getMe();
        }
        setIdentified(true);
      } catch (err) {
        saveAccessToken('');
        saveImpersonateAccessToken('');
        const { path, backTo } = getLoginRedirectUrl();
        router.push(`${path}${backTo}`);
      }
    }
    identifyUser();
  }, [dispatch, refreshToken, getMe, router, router.isReady]);

  useEffect(() => {
    initializeDatadogRum();
  }, []);

  return identified ? children : null;
}

function MyApp({ Component, pageProps, error }: any) {
  if (error) {
    return <h1>{error}</h1>;
  }

  const getLayout = Component.getLayout || ((page) => page);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <AuthProvider>
        <Provider store={store}>
          <GoogleOAuthProvider
            clientId={process.env.NEXT_PUBLIC_GOOGLE_OAUTH_CLIENT_ID}
            onScriptLoadError={() => {
              console.error('Failed to load Google OAuth. Reach out to Tilt support.');
            }}
          >
            <QueryClientProvider client={queryClient}>
              <SafeIdentify>
                <ThemeProvider>
                  <UUIDProvider>
                    <NavigationProvider>
                      <SafeSetReduxDispatchOnWindow />
                      <Head>
                        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
                        <title>Tilt</title>
                      </Head>
                      <PlanProvider>
                        {getLayout(
                          <KustomerWrapper>
                            <PendoInitializer />
                            <Component {...pageProps} />
                          </KustomerWrapper>,
                        )}
                      </PlanProvider>
                      <ReactQueryDevtools initialIsOpen={false} />
                    </NavigationProvider>
                  </UUIDProvider>
                </ThemeProvider>
              </SafeIdentify>
            </QueryClientProvider>
            <Toast />
          </GoogleOAuthProvider>
        </Provider>
      </AuthProvider>
    </LocalizationProvider>
  );
}

export default MyApp;
