import { BadgeColor } from '@components/v2/Badge/Badge';
import { IDocument, ITimeOffBalance } from '@constants/types';

export type {
  IPerLeaveBenefitUsage,
  IBenefitUsage,
  IBenefitMetadataUsage,
  IPerLeaveBenefitDateRange,
} from './PerLeaveBenefitUsage';

import * as CompanyTypes from './companies';
export { ECompanyStatus } from './companies';
export { EBenefitTimeUnit } from './PerLeaveBenefitUsage';

import { IPayrollPayPeriods } from '../screens/Hr/payroll/components/generatePayrollColumns';

export interface IRuleVM {
  benefitTitle: string;
  id: number;
  name: string;
  template: IPayCalcItemTemplateVM | null;
}

export interface IBenefitResultVM {
  id: number;
  awake?: boolean;
  eligible: boolean | null;
  paycalcItems: IPayCalcItemVM[];
  missing_data: boolean;
  exception_msg: string | null;
  reasoning: BenefitReasoning;
  reasonsIneligible: string[] | undefined;
  leave: number;
  rule: IRuleVM;
  hasMultipleDateSpans?: boolean;
  templateUuid?: string;
}

export interface BenefitReasoning {
  awake: string[];
  eligible: string[];
  spans: string[];
}

export enum EHrIntegrationType {
  Bamboohr = 'BAMBOOHR',
}

export interface IHrIntegrationConfig {
  domain: string;
  apiKey: string;
}

export interface IHrIntegrationTestResult {
  id?: string | number;
  displayName?: string;
  workEmail?: string;
}
export interface ICompanySettingsVM {
  id: number;
  companyId: number;
  disableAbcForIntermittentLeaves: boolean;
  isPublicCompany: boolean;
  ctFmlaExempt: boolean;
  disableEeWagesBke: boolean;
  orEmployerMetHeadcount: boolean;
  selfFunded: boolean;
  sendFmlaDocs: boolean;
  dontIngestHireDate: boolean;
  hideBenefitStatusesMgrs: boolean;
}
export interface ICompanyVM extends ISAMLSettings {
  id: number;
  uuid: string;
  name: string;
  subdomain: string;
  logo: string | null;
  primaryColor: string | null;
  secondaryColor: string | null;
  createdAt: string;
  isFmlaEligible: boolean;
  integrationLastSynced?: string | null;
  supportEmail: string | null;
  updatedAt: string;
  deletedAt: string | null;
  hrIntegrationType: EHrIntegrationType;
  hrIntegrationConfig?: IHrIntegrationConfig;
  users: IUserVM[];
  plans: IPlanVM[];
  enforceSingleSignOn: boolean;
  hasPayPush?: boolean;
  isRipplingIntegrated: boolean;
  isWorkdayIntegrated?: boolean;
  ripplingSsoEnabled?: boolean;
  twoFactorEnabled?: boolean;
  enforceFmla50And75Rule?: boolean;
  allowRipplingIntegrationInstall?: boolean;
  settings?: ICompanySettingsVM;
  remoteCompanyId: string | null;
  userCount?: number;
  isDemo?: boolean;
  leaveCount?: number;
  activeLeaveCount?: number;
  employeeCount?: number;
  employeesInUsa?: number;
  isSftpEnabled?: boolean;
  status: CompanyTypes.ECompanyStatus;
  rolePermissions?: IRolePermissionVM[];
  paysOnHolidays?: boolean;
}
export interface ISftpUser {
  id: number;
  first_name: 'SFTP' | string;
  last_name: string;
  email: string;
  personal_email: string | null;
  employee_id: number | null;
  logged_in: boolean;
  phone: string | null;
}
export interface ISftpPasswordArgs {
  password: string;
  confirmPassword: string;
}
export enum EPermissionValue {
  ADD = 'add',
  CHANGE = 'change',
  DELETE = 'delete',
}
export interface IPermissions {
  stepTemplates: EPermissionValue[];
  coreStepTemplates: EPermissionValue[];
  flags: EPermissionValue[];
  taskTemplates: EPermissionValue[];
  payCalcItemTemplates: EPermissionValue[];
  rolePermissions: EPermissionValue[];
  companies: EPermissionValue[];
}

export interface IUserVM {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  personalEmail: string | null;
  pronouns?: string | null;
  phone: string | null;
  emergencyContactName: string | null;
  emergencyContactRelationship: string | null;
  emergencyContactPhone: string | null;
  superuser: boolean;
  isHr: boolean;
  company?: ICompanyVM;
  isPayroll: boolean;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  userAccess?: IUsereAccessVM;
  isArchived?: boolean;
  employeeId: string | null;
  profile?: IProfileVM;
  impersonating?: boolean | null;
  benefitsCount?: number;
  fmlaEligible?: boolean;
  lastLogin?: string;
  isIntegrated?: boolean;
  remoteEmployeeId?: string;
  permissions?: IPermissions;
  ignore2faSetup?: boolean;
  isContractor: boolean;
  groups?: IAuthGroupVM[];
  role?: number;
  cohorts?: string[];
  cohortOwnerNames?: string[];
  rolePermissions?: IRolePermissionVM[];
}
export interface IPartialUserVM {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  employeeId?: string;
  personalEmail?: string;
  phone?: string | null;
  loggedIn?: boolean;
  sftpMethod?: string;
  isArchived?: boolean;
  remoteEmployeeId?: number | null;
}

export interface IUserAggregatedData {
  id: number;
  workingState?: string;
  homeState?: string;
  homeCountry?: string;
  assignedLsm?: number;
  leaveCount?: number;
  managedLeaveCount?: number;
}

export interface IUsereAccessVM {
  userId: number;
  companyId: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
}
export interface ISAMLIdpCertFileVM {
  s3Path: string;
  fileName: string;
}

export interface IStepsVM {
  required: IStepVM[];
  suggested: IStepVM[];
  resources: IStepVM[];
}

export interface IPlanVM {
  id: number;
  title: string;
  active: boolean;
  // TODO this should be in UTC/epoch
  dueDate: string;
  returnDate: string | null;
  type: number;
  typeTitle: string | null;
  payroll: IPayrollVM[];
  fmla: boolean;
  customTags: string[];
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  companyId: number;
  company?: ICompanyVM;
  managerId: number;
  employeeId: number;
  steps?: IStepsVM;
  leave?: ILeaveHRVM;
  schedule?: IScheduleVM;
  manager: IUserVM;
  employee: IUserVM;
  items?: IPayCalcItemVM[];
  paycalcSet?: IPayCalcVM[];
  paycalc?: IPayCalcVM;
  draftPaycalc?: IPayCalcVM;
  publishedPaycalc?: IPayCalcVM;
  intermittent: boolean;
  archived: boolean;
  files?: IFileVM[];
  tags?: string[];
  hasPayPush?: boolean;
  hasPinnedNotes?: boolean;
  paysOnHolidays?: boolean;
}
export interface IPlanStageOverviewVM {
  stage: number;
  employeeCount: number;
}

export interface IPlanMonthlyOverviewVM {
  month: number;
  employeeCount: number;
}

export interface IPlanAdminVM extends IPlanVM {
  leave?: ILeaveAdminVM;
  paysOnHolidays?: boolean;
  duplicateSteps?: Record<
    string,
    {
      coreTitle?: string;
      duplicates: Record<string, { title: string; role: string; chapter: string }>;
    }
  >;
}

export interface IPlanCountVM {
  count: number;
}

export enum EPlanStatus {
  NEEDS_REVIEW = 'NEEDS_REVIEW',
  PENDING_TASKS = 'PENDING_TASKS',
}

export interface IPendingWeekVM {
  fromField: string;
  toField: string;
  minutesPerDayMask?: number[];
  rangeId?: number;
}

interface IPlanDetailsVM {
  planId: number;
  employeeId: number;
  managerId: number;
  companyId: number;
}

export enum EStepCategory {
  MUST_DO = 'MUST_DO',
  SHOULD_DO = 'SHOULD_DO',
  RESOURCE = 'RESOURCE',
}

export interface IStepVM {
  attachments: string[];
  automated?: boolean;
  category: EStepCategory;
  chapter: number;
  completedAt?: string;
  createdAt?: string;
  deletedAt?: string;
  description: string;
  displayFileUpload?: boolean;
  done: boolean;
  dueDate?: string;
  id: number;
  order: number;
  plan?: IPlanDetailsVM;
  planId?: number;
  startDate?: string;
  stepTemplate: string | number;
  stepTemplateId?: string | number;
  template?: IMiniStepTemplateVM;
  templateAttachments: string[];
  title: string;
  updatedAt?: string;
  urls: string[];
  userRole: number;
  videoLinks?: string[];
}

export interface IDocumentStep extends IDocument {
  done: boolean;
  title: string;
  completedAt: string | null;
}
export interface IMiniStepTemplateVM {
  id: number;
  internalTitle: string;
  priority: number;
  title: string;
}

export interface IStepTemplateVM {
  id: number;
  title: string;
  description: string;
  urls: string[];
  videoLinks?: string[];
  category: string;
  chapter: number;
  attachments: IAttachmentVM[];
  userRole: number;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  displayFileUpload?: boolean;
  internalTitle: string;
  tags: string[];
  managedTags: string[];
  priority: number;
  company?: { id: number; name: string };
  coreStep?: ICoreStepTemplateVM;
  paycalcItemTemplate?: number;
  dueDate?: string;
  startDate?: string;
}

export interface ICoreStepTemplateVM {
  id: number;
  title: string;
  description: string;
  urls: string[];
  videoLinks?: string[];
  category: string;
  chapter: number;
  attachments: IAttachmentVM[];
  userRole: number;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  displayFileUpload?: boolean;
  internalTitle: string;
  priority: number;
  managedTags: string[];
  stepTemplatesCount: number;
  paycalcItemTemplate?: number;
  dueDate?: string;
  startDate?: string;
}

export enum EScheduleType {
  LEAVE_REGULAR = 'LEAVE_REGULAR',
  WORK_REGULAR = 'WORK_REGULAR',
  BENEFIT = 'BENEFIT',
  LEAVE_SURVEY = 'LEAVE_SURVEY',
}

export enum EScheduleDayStatus {
  APPROVED = 'APPROVED',
  DENIED = 'DENIED',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  PENDING_DELETION = 'PENDING_DELETION',
}

export const EScheduleDayStatusBadgeColorMap = {
  APPROVED: { name: 'Published', color: BadgeColor.PRIMARY_DARK },
  DENIED: { name: 'Denied', color: BadgeColor.CORAL },
  PENDING_APPROVAL: { name: 'Pending', color: BadgeColor.PUMPKIN },
  PENDING_DELETION: { name: 'Pending Deletion', color: BadgeColor.CORAL },
};

export enum EConfirmDateType {
  End = 'END',
  Start = 'START',
}

export interface IScheduleDaysVM {
  id?: number;
  userId?: number;
  from: string;
  fromField: string;
  to: string;
  toField: string;
  totalMinutes?: number;
  minutesPerDayMask: number[];
  updatedAt?: string;
  title: string;
  type: EScheduleType;
  notes: string;
  errors?: Record<string, any>;
  requesterNotes?: string;
  reviewerNotes?: string;
  reviwerId?: number;
  status: EScheduleDayStatus;
  lastApprovedInstance: Record<string, any>;
  leave?: IUserLeaveVM | number;
  benefitUuid: string | null;
  benefitEntitlement: IBenefitEntitlement[];
  benefit: IBenefit | null;
  historyUser?: IUserVM;
  historyImpersonator?: IUserVM;
  historyDate?: string;
  historyId?: number;
  notScheduled: boolean;
}

export interface IBenefit {
  template: IPayCalcItemTemplateVM;
}

export interface IBenefitEntitlement {
  startDate: string;
  endDate: string;
  minutes: number;
}

export enum EBenefitTrackingMethod {
  ROLLING_BACK = 'ROLLING_BACK',
  ROLLING_FORWARD = 'ROLLING_FORWARD',
  ROLLING_FORWARD_SUNDAY = 'ROLLING_FORWARD_SUNDAY',
  CALENDAR_YEAR = 'CALENDAR_YEAR',
  FIXED_YEAR = 'FIXED_YEAR',
  EVENT_BASED = 'EVENT_BASED',
}

export interface IScheduleVM {
  days: IScheduleDaysVM[];
  fmlaAvailableMinutes: number;
  fmlaEligibilityTracking?: any;
  fmlaEntitledWeeks: number;
  fmlaExhaustedMinutes: number;
  fmlaScheduledMinutes: number;
  userId: number;
  // TODO shared enum?
  type: string;
  workingWeekMinutes?: any;
  // TODO should we expose this?
  createdAt: string;
}

export interface IPayrollVM {
  name?: string;
  url?: string;
}

export enum EFieldTypes {
  USER = 'user',
  LOCATIONS = 'locations',
  COUNTRIES = 'countries',
  COMMUNICATION_TYPE = 'COMMUNICATION_TYPE',
  PRE_BIRTH_TIME_REASON = 'PRE_BIRTH_TIME_REASON',
  DAY_OF_WEEK = 'DAY_OF_WEEK',
  EXEMPTION_TYPE = 'EXEMPTION_TYPE',
  EMPLOYEE_TYPE = 'EMPLOYEE_TYPE',
  LEAVE_TYPE = 'LEAVE_TYPE',
  NY_LEAVE = 'NY_LEAVE',
  YES_NO = 'YES_NO',
  YES_NO_NOTSURE = 'YES_NO_NOTSURE',
  PAY_CALENDARS = 'PAY_CALENDARS',
  HOURLY_TYPE = 'HOURLY_TYPE',
  CAREGIVER_LEAVE_TYPE = 'CAREGIVER_LEAVE_TYPE',
  LEAVE_CONTINUOUS = 'LEAVE_CONTINUOUS',
  SALARY_PERIOD = 'SALARY_PERIOD',
  HOME_COUNTRIES = 'HOME_COUNTRIES',
  US_STATES = 'US_STATES',
  CA_PROVINCES = 'CA_PROVINCES',
  TYPICAL_WORK_SCHEDULE_EE = 'TYPICAL_WORK_SCHEDULE_EE',
  TYPICAL_WORK_SCHEDULE_HR = 'TYPICAL_WORK_SCHEDULE_HR',
}

export enum EFieldInputTypes {
  SELECT = 'select',
  MULTI_SELECT = 'multi-select',
  DATE = 'date-select',
  STRING_INPUT = 'string-input',
  NUMBER_INPUT = 'number-input',
  DECIMAL_INPUT = 'decimal-input',
  TEXTAREA_INPUT = 'textarea-input',
  RADIO_GROUP = 'radio-group',
  CHECKBOX_GROUP = 'checkbox-group',
  FILE_UPLOAD = 'file-upload',
  CHECKBOX = 'checkbox',
  WEEKDAY_INPUT = 'weekday-input',
  NO_INPUT = 'no-input',
  BUTTON = 'button',
}

type RequireAtLeastOne<T, Keys extends keyof T = keyof T> = Pick<T, Exclude<keyof T, Keys>> &
  {
    [K in Keys]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<Keys, K>>>;
  }[Keys];

export interface IDependOnField {
  id: string;
  value: string;
  // If not passed it will default to equals
  operator?: string;
}

interface IDependsOn {
  or?: IDependOnField[];
  and?: IDependOnField[];
}

export type TLogicOperatorsDependsOn = RequireAtLeastOne<IDependsOn, 'or' | 'and'>;

export interface IWarnOn {
  value: string;
  html: string | JSX.Element;
}

export interface IMoreThanField {
  id: string;
}

interface IYupValidationVM {
  type: string;
  format: string;
  formatMessage: string;
  max: number;
  maxMessage: string;
  min: number;
  minMessage: string;
}

export interface ILeaveFormFieldVM {
  id: string;
  uid?: string;
  label: string;
  arrayLabels?: string[];
  inputType: EFieldInputTypes;
  optional?: boolean;
  hidden?: boolean;
  twoColumnLayout: boolean;
  options: EFieldTypes | string | any[]; // Constant | field | inline values
  dependsOn?: IDependOnField | TLogicOperatorsDependsOn;
  moreThan?: IMoreThanField;
  readOnly?: boolean;
  help?: string;
  warnOn?: IWarnOn[];
  placeholder?: boolean;
  flag?: string;
  searchPlaceholder?: string;
  html?: string;
  description?: string;
  readOnlyDescription?: string;
  yupValidation?: IYupValidationVM;
  disabled: boolean;

  optionsEndpoint?: string;
  initialValuesEndpoint?: string;
  initialValueMap?: { [integrationType: string]: string };
  initialValue?: string | any[] | boolean | number;
}

export interface ILeaveFormDataTypeVM {
  value: string;
  label: string;
}

export interface ILeaveFormDataVM {
  COMMUNICATION_TYPE: ILeaveFormDataTypeVM[];
  DAY_OF_WEEK: ILeaveFormDataTypeVM[];
  EMPLOYEE_TYPE: ILeaveFormDataTypeVM[];
  LEAVE_TYPE: ILeaveFormDataTypeVM[];
  NY_LEAVE: ILeaveFormDataTypeVM[];
  YES_NO: ILeaveFormDataTypeVM[];
  YES_NO_NOTSURE: ILeaveFormDataTypeVM[];
  PRE_BIRTH_TIME_REASON: ILeaveFormDataTypeVM[];
  fields: ILeaveFormFieldVM[];
  useDisengagedEmployeeFeature: boolean;
  users: IUserVM[];
  managers: IUserVM[];
  hrIntegrationType: EHrIntegrationType;
  initialValues?: ICreateNewLeaveVM;
  payCalendars: ILeaveFormDataTypeVM[];
}

// @TODO better types: here it can be an object (file: name, url), number, string, array of string but it makes validations very hard
export interface ICreateNewLeaveVM {
  [key: string]: any;
}

export interface ICreateNewPolicyVM {
  tos?: boolean;
  id?: number;
  companyId?: number;
  employeeCount: string;
  employeesInUSA: number;
  workingStates: string[];
  hasEmployeesAbroad: string;
  restrictWorkEmail: string;
  employeesAbroadCountries: string[];
  holidays: string[];
  hasPaidHolidayLOA: string;
  hasFmlaConcurrent: string;
  hasFmlaCalendarYear: string;
  policies: string[];
  parentalMinLength: string;
  canParentalIntermittent: string;
  minLeaveIncrements: string;
  canAnticipatedLeave: string;
  canPTOStack: string;
  maxPTOStack: string;
  isStateBenefitsFirst: string;
  hasBreastfeedPlace: string;
  isBreastfeedPlaceBooked: string;
  breastfeedBookedDesc: string;
  addDependantSteps: string;
  addDependantDocs: string;
  leaveDueDate: string;
  hasSTDBenefits: string;
  feedback: string;
  hasLTDBenefits: string;
  disabilityBenefitsContact: string;
  handbookFile: string;
  payCalendarFile: string;
  usHiHeadcountMet: boolean | null;
  usHiFamliTracking: EBenefitTrackingMethod | null;
  usMdPlaApplies: boolean | null;
  usMdPlaDefined: boolean | null;
  usMdPlaTracking: EBenefitTrackingMethod | null;
  usMdPlaWorksiteEnforced: boolean | null;
  usMeFmlApplies: boolean | null;
  usRiIsMunicipality: boolean | null;
  usRiIsStateAgency: boolean | null;
  //internal only
  parsed: number;
}

export interface IKnownPolicyProperties {
  companyId: number;
  createdAt: string;
  deletedAt: string;
  id: number;
  status: string;
  updatedAt: string;
}

export type IPolicyVM = IKnownPolicyProperties &
  Partial<{
    [key: string]: string | string[];
  }>;

export enum ELeaveType {
  PARENTAL = 'PARENTAL',
  MEDICAL = 'MEDICAL',
  CAREGIVER = 'CAREGIVER',
  MILITARY = 'MILITARY',
  OTHER = 'OTHER',
}

export enum ELeaveTypeOptions {
  PARENTAL = 'Parental',
  CAREGIVER = 'Caregiver',
  MEDICAL = 'Medical',
  MILITARY = 'Military',
  OTHER = 'Other',
}

export enum ELeaveStatus {
  DRAFT = 'DRAFT',
  SENT_TO_EMPLOYEE = 'SENT_TO_EMPLOYEE',
  SENT_TO_HR = 'SENT_TO_HR',
  APPROVED_EMPLOYEE = 'APPROVED_EMPLOYEE',
  APPROVED_HR = 'APPROVED_HR',
  REJECTED_HR = 'REJECTED_HR',
}

export enum EExemptionType {
  EXEMPT = 'EXEMPT',
  NON_EXEMPT = 'NON_EXEMPT',
}

export enum EEmployeeType {
  FULL_TIME = 'FULL_TIME',
  PART_TIME = 'PART_TIME',
  TEMPORARY = 'TEMPORARY',
  SEASONAL = 'SEASONAL',
  // Deprecated
  // NOT_SURE = 'NOT_SURE',
  // OTHER = 'OTHER',
}

// Based on Leave Model, class BirthType(models.IntegerChoices):
// since TS enums are 0 indexed, we need to add a null value at index 0
export enum EBirthType {
  'Null',
  'Vaginal',
  'C-Section',
}

interface IKnownLeaveProperties {
  id: number;
  status: ELeaveStatus;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  companyId: number;
  managerId: number;
  employeeId: number;
  planId?: number;
  plan: IPlanVM;
  hadFmlaDescription: string;
  isLeaveContinuous: boolean;
  intermittentLeavePlan: string;
  usesIrregularSchedule: boolean;
  workingDays: number[];
  workingState: string;
  expectedLeaveDate: string;
  dueDate?: string;
  leaveType: ELeaveType;
  birthType?: EBirthType;
  expectedChildren?: number;
  displayBirthDetails?: boolean | null;
  birthDetailsConfirmed?: boolean | null;
  expectedDueDate?: string | null;
  isPregnant?: boolean | null;
  expectedReturnDate?: string;
  uuid: string;
  employeeDisengaged?: boolean;
}

export enum ESalaryPeriod {
  ANNUALLY = 'ANNUALLY',
  MONTHLY = 'MONTHLY',
  WEEKLY = 'WEEKLY',
  HOURLY = 'HOURLY',
}

export interface IProfileVM {
  isExempt: boolean;
  isHourly?: boolean;
  contractTypes: string[];
  contractTypesOther: string;
  createdAt: string;
  eeNyWorkScheduleDetails?: number;
  employeeId: string;
  enrolledHealthBenefits: boolean;
  enrolledLtDisability: boolean;
  enrolledStDisability: boolean;
  hasMetCoEarnings: boolean;
  hasMetMaEarnings: boolean;
  hasMetCtEarnings: boolean;
  hasMetWaHours: boolean;
  hasMetNjEarnings: boolean;
  hasMetOrEarnings: boolean;
  hasMetHiEarnings: boolean;
  hasMetHiHours: boolean;
  hasMetRiEarnings: boolean;
  hasDirectReports: boolean;
  hasWorkedMore: string; // YES, NO, NOT_SURE
  hireDate: string;
  isWorksiteUsMdPlaEligible: boolean;
  isWorksiteUsMeFmlEligible: boolean;
  id: number;
  hasMoreThanOneJob: boolean;
  jobTitle: string;
  manager: number;
  managerEmail: string;
  managerFirstName: string;
  managerLastName: string;
  typicalWorkSchedule: EWorkScheduleType | null;
  workingDays: number[];
  minutesPerWeek: number;
  topUpHours: number;
  topUpAmount: number;
  salary: number;
  salaryPeriod: ESalaryPeriod;
  userAccess: number;
  homeCountry?: string;
  homeState?: string;
  workingState: string;
  workingCity?: string;
  birthType?: string;
  // this is for internal usage
  parsed?: number;
  benefitsCount?: number;
  calendarId?: number | null;
  timeOffBalance?: ITimeOffBalance[] | null;
  usesIrregularSchedule?: boolean;
  remoteEntityId?: string;
  leaveCount?: number;
}

export enum EWorkScheduleType {
  SET = 'SET',
  UNPREDICTABLE = 'UNPREDICTABLE',
}

export const paidPerChoices = {
  ANNUALLY: 'Year',
  MONTHLY: 'Month',
  WEEKLY: 'Week',
  HOURLY: 'Hour',
};

export const workingDayLabels = ['SUN', 'MON', 'TUE', 'WED', 'THURS', 'FRI', 'SAT'];

export interface ILeaveHRVM extends IKnownLeaveProperties {
  id: number;
  archived: boolean;
  employeeId: number;
  status: ELeaveStatus;
  employeeFirstName: string;
  employeeLastName: string;
  employeeEmployeeId: string;
  expectedReturnDate: string;
  assigneeId: number;
  homeCountry?: string;
  assignee?: IUserVM;
  communicationPreLeaveValue?: string;
  communicationLeaveValue?: string;
  company?: { id: number; name: string; subdomain: string; hideBenefitsStatusesMgrs?: boolean };
}

export interface ILeaveAdminVM extends ILeaveHRVM {
  docFolderId: string;
  isIntegrated?: boolean;
  communicationPreLeave?: ELeaveCommunicationType | null;
  communicationPreLeaveValue?: string | null;
  communicationLeave?: ELeaveCommunicationType | null;
  communicationLeaveValue?: string | null;
  customTags?: string[];
}

// * Currently unused but if we start formatting phone numbers
// * having this here will be useful
export enum ELeaveCommunicationType {
  EMAIL = 'EMAIL',
  TEXT = 'TEXT',
}

export type IUserLeaveVM = IKnownLeaveProperties &
  Partial<{
    [key: string]: string | string[] | number | number[] | boolean;
  }>;

// Pay Calc
export enum EPayPeriodLength {
  WEEKLY = 'WEEKLY',
  BIWEEKLY = 'BIWEEKLY',
  SEMIMONTHLY = 'BIMONTHLY',
  MONTHLY = 'MONTHLY',
}

export enum EPayPeriodLengthInWeeks {
  BIWEEKLY = 26,
  BIMONTHLY = 24,
}

// TODO Ale looking at this aren't we repeating many types?
export enum EPayPeriodUnit {
  WEEK = 'WEEK',
  CALENDAR_DAY = 'CALENDAR_DAY',
  WORKING_DAY = 'WORKING_DAY',
  HOURLY = 'HOURLY',
}

export interface IPayPeriodItem {
  unit: EPayPeriodUnit;
  title: string;
  amount: number;
  periodsCount: number;
  totalPeriods: number;
  topsUp?: boolean;
  weekLength?: number;
  totalMinutes?: number;
  adjustment?: string;
  adjustmentAmount?: number;
  adjustmentTotalMinutes?: number;
  adjustmentPeriodsCount?: number;
  isPaidByCompany?: boolean;
  pk?: number;
  earningType?: EEarningTypes;
}

export interface IPayPeriodVM {
  to: string;
  from: string;
  meta: {
    toIndex: number;
    fromIndex: number;
  };
  fromDate?: string;
  toDate?: string;
  paidAt?: string;
  items: IPayPeriodItem[];
  totalPayments: number;
  periodStatus: string;
}

export interface IPayCalcErrors {
  title: string;
  from: string;
  to: string;
  maxDurationMinutes: number;
  totalMinutes: number;
}

export enum EPayCalcState {
  DRAFT = 'draft',
  PUBLISHED = 'published',
  DELETED = 'deleted',
  ARCHIVED = 'archived',
  PUBLISH_PENDING = 'publish_pending',
}

export interface IPayCalcVM {
  id?: number;
  planId?: number;
  notes?: string;
  payPeriods?: IPayPeriodVM[];
  payPeriodLength: EPayPeriodLength;
  startDate: string;
  endDate: string;
  // payPerPeriod is a string on the BE but we
  // want to limit user input to only numbers
  payPerPeriod?: number | string;
  items?: IPayCalcItemVM[];
  editable?: boolean;
  status?: EPayCalcState | null;
  adjustmentReasonings?: string[];
  updatedAt?: string;
  errors?: IPayCalcErrors[];
  warnings?: string[];
  createdAt?: string;
  publishedAt?: string;
  archivedAt?: string;
  publishPendingAt?: string;
}

export interface IPayCalcPayrollVM {
  planId?: number;
  payPeriods?: IPayrollPayPeriods[] | null;
  startDate: string;
  endDate: string;
  plan: IPlanVM;
  leaveLeaveType?: string;
  profileWorkingState?: string;
  payCalendar?: number;
  payCalendarName?: string;
  payPeriodClosure?: IPayPeriodClosure;
  adjustmentReasonings?: string[];
  unsettledAdjustments?: IPayrollPayPeriods[];
  addedPayPeriods?: IPayrollPayPeriods[];
  periodStartDate?: string;
  periodEndDate?: string;
}
export interface ISmallPayPeriodVM {
  startDate: string;
  endDate: string;
  calendarName: string;
  remoteSource?: ECalendarRemoteSource;
  calendarId: number;
  approved?: string;
  approver?: string;
}
export interface ICsvPayrollReportVM {
  payPeriodData?: ISmallPayPeriodVM;
  headers: string[];
  tableData: string[][];
}

export interface IReportParams {
  calendarName: string;
  calendarId: number;
  remoteSource?: ECalendarRemoteSource;
  toDate: string;
  fromDate: string;
  isClosed?: boolean;
}
export interface ILeaveDataVM {
  id: number;
  dueDate: string;
  returnDate: string;
  schedule?: IScheduleVM;
  items: IPayCalcItemVM[];
  paycalcSet?: IPayCalcVM[];
  paycalc?: IPayCalcVM;
  publishedPaycalc?: IPayCalcVM;
}

export enum EPaycalcItemLength {
  PAY_PERIOD = 'PAY_PERIOD', // pays once per pay-period
  MONTHLY = 'MONTHLY',
  WEEKLY = 'WEEKLY',
  CALENDAR_DAY = 'CALENDAR_DAY',
  WORKING_DAY = 'WORKING_DAY',
  HOURLY = 'HOURLY',
}

export enum EProrationMethod {
  CALENDAR_DAYS = 'CALENDAR_DAYS',
  WORKING_DAYS = 'WORKING_DAYS',
}

export enum ERoundingMethod {
  NONE = 'NONE',
  ALWAYS_ROUND_UP = 'ALWAYS_ROUND_UP',
  ROUND_TO_NEAREST_DOLLAR = 'ROUND_TO_NEAREST_DOLLAR',
}

export enum EMaxDurationUnit {
  HOUR = 'HOUR',
  DAY = 'DAY',
  WEEK = 'WEEK',
}

export enum EEarningTypes {
  COMPANY_REGULAR_WAGES = 'COMPANY_REGULAR_WAGES',
  STD_PAID_BENEFIT = 'STD_PAID_BENEFIT',
  LTD_PAID_BENEFIT = 'LTD_PAID_BENEFIT',
  STATE_PAID_BENEFIT = 'STATE_PAID_BENEFIT',
  STATE_UNPAID_BENEFIT = 'STATE_UNPAID_BENEFIT',
  FEDERAL_GOVERNMENT_PAID_BENEFIT = 'FEDERAL_GOVERNMENT_PAID_BENEFIT',
  FEDERAL_GOVERNMENT_UNPAID_BENEFIT = 'FEDERAL_GOVERNMENT_UNPAID_BENEFIT',
  TILT_ADMINISTRATIVE_UNPAID = 'TILT_ADMINISTRATIVE_UNPAID',
  COMPANY_PAID_PARENTAL_LEAVE = 'COMPANY_PAID_PARENTAL_LEAVE',
  COMPANY_PAID_CAREGIVER_LEAVE = 'COMPANY_PAID_CAREGIVER_LEAVE',
  COMPANY_PAID_MEDICAL_LEAVE = 'COMPANY_PAID_MEDICAL_LEAVE',
  COMPANY_PAID_MILITARY_LEAVE = 'COMPANY_PAID_MILITARY_LEAVE',
  COMPANY_PAID_EXTRAS = 'COMPANY_PAID_EXTRAS',
  COMPANY_PAID_LEAVE_OTHER = 'COMPANY_PAID_LEAVE_OTHER',
  COMPANY_UNPAID_LEAVE = 'COMPANY_UNPAID_LEAVE',
  COMPANY_HOLIDAY_PAY = 'COMPANY_HOLIDAY_PAY',
  COMPANY_PTO_PAY = 'COMPANY_PTO_PAY',
  COMPANY_SICK_PAY = 'COMPANY_SICK_PAY',
  MILITARY_PAY = 'MILITARY_PAY',
  CANADIAN_PAID_BENEFIT = 'CANADIAN_PAID_BENEFIT',
}

export enum EPayCalcItemType {
  GENERIC = 'GENERIC',
}

export interface IPayCalcItemVM {
  id?: number;
  title: string;
  notes?: string;
  from: string;
  to: string;
  fromField?: string;
  toField?: string;
  order?: number;
  // payPerPeriod, maxPayPerPeriod, payPercentage and maxDuration
  // are strings on the BE but we want to limit user input to only numbers
  payPerPeriod: number | string;
  maxPayPerPeriod: number | string;
  maxDuration?: number | string;
  maxDurationUnit?: EMaxDurationUnit;
  payPercentage: number | string;
  waitingPeriod: number;
  payPeriodLength: EPaycalcItemLength;
  prorationMethod: EProrationMethod;
  roundingMethod: ERoundingMethod;
  topsUpAmount: boolean;
  planId: number;
  paysOnLeaveDays: boolean;
  paysOnWorkedDays: boolean;
  isBenefit?: boolean;
  // TODO define types, we should remove this type field
  type: EPayCalcItemType;
  paycalcItemTemplateId: number;
  template: IPayCalcItemTemplateVM;
  stats: IPayCalcItemStats;
  adjustmentReasonings?: string[];
  earningType?: EEarningTypes;
  precomputed?: boolean;
  reasoning?: object;
  automated?: boolean;
  result?: number | null;
  paycalcId?: number;
  plan?: number;
  documentClass?: number;
  documentClassChangedReason?: string;
  documentClassChangedBy?: number;
  documentClassChangedAt?: string;
  needsManualApproval: boolean;
  status: BenefitStatusInfo;
}

export interface ICompanyFromMiniSerializer {
  id: number;
  name: string;
  subdomain: string;
}

export interface IPayCalcItemTemplateVM {
  id?: number;
  color: string;
  description: string;
  help: string;
  title: string;
  payPerPeriod?: number | string;
  payPercentage?: number | string;
  maxPayPerPeriod?: number | string;
  payPeriodLength: EPaycalcItemLength;
  prorationMethod?: EProrationMethod;
  roundingMethod?: ERoundingMethod;
  topsUpAmount?: boolean;
  paysOnWorkedDays?: boolean;
  paysOnLeaveDays?: boolean;
  waitingPeriod?: number;
  order: number;
  maxDuration: number;
  maxDurationUnit?: EMaxDurationUnit;
  isPaidByCompany?: boolean;
  requiresApplication?: boolean;
  earningType?: EEarningTypes;
  caregiverType?: string[];
  uuid?: string;
  company: ICompanyFromMiniSerializer | null;
  areBonusesIncluded: boolean | null;
  trackingMethod: EBenefitTrackingMethod | null;
  externalTitle: string | null;
  insuranceCompany: string | null;
  effectiveStartDate: string | null;
  effectiveEndDate: string | null;
  documentClass?: number | null;
  needsManualApproval: boolean;
  hideWhenDenied: boolean;
}

export interface IPayCalcItemTemplateResultVM extends IPayCalcItemTemplateVM {
  paycalcItemTemplateId?: number;
}

export type InsuranceProviderElement = {
  value: string;
  label: string;
  name: string;
};

export type InsuranceProviderResponseVM = InsuranceProviderElement[];

export interface LeaveUsage {
  startDate: string;
  accumulatedInPeriod: number[];
  usedInPeriod: number[];
  usagePeak: number;
  usagePeakDate: string;
  method: string;
}
export interface IPayCalcItemStats {
  maxDays?: number;
  usedDays?: number;
  maxMinutes?: number;
  usedMinutes?: number;
  weekDays?: number;
  weekMinutes?: number;
  usageDetail: LeaveUsage;
}

export interface ISAMLSettings {
  oktaSsoEnabled: boolean;
  oktaSsoEntryPointUrl: string | null;
  oktaIdpEntityId: string | null;
  oktaIdpCertFile: ISAMLIdpCertFileVM;
}

export interface ITwoFactorEnableVM {
  success: boolean;
  otpauthURL: string;
  type: string;
}

export interface IVerifyTwoStepMethodVM {
  token2fa: string;
  tokenType: string;
  remember2fa: boolean;
}

export interface ILeaveResultVM {
  isReadOnly: boolean;
  leaveData: IUserLeaveVM;
  leaveForm: ILeaveFormDataVM;
}

export interface IPolicyResultVM {
  policyData: IUserLeaveVM;
  policyForm: ILeaveFormDataVM;
}

export interface ICompanyResultVM {
  company: ICompanyVM;
}

export interface IUserResultVM {
  user: IUserVM;
}

export interface ITaskVM {
  id: number;
  plan: IPlanVM;
  taskTemplate: ITaskTemplateVM | null;
  title: string;
  notes: string;
  dueDate: string | null;
  done: boolean;
  doneAt: string | null;
  order: number;
  createdAt: string;
  updatedAt: string;
  toList?: string[];
  messageSubject?: string;
  messageContent?: string;
}

export enum EUserRoles {
  EMPLOYEE = 'EMPLOYEE',
  HR = 'HR',
  PAYROLL = 'PAYROLL',
  MANAGER = 'MANAGER',
  CS = 'CS',
}

export interface ITaskTemplateVM {
  id: number;
  code: string;
  title: string;
  notes: string;
  tags: string[];
  dueDate: string;
  userRole: EUserRoles | null;
  messageSubject: string | null;
  messageContent: string | null;
  externalContent: string | null;
  hideTemplateEmail?: boolean;
}

export interface ITaskResultVM {
  count: number;
  next?: string;
  previous?: string;
  results: ITaskVM[];
}

export interface ITaskTemplateResultVM {
  count: number;
  next?: string;
  previous?: string;
  results: ITaskTemplateVM[];
}

export interface INoteVM {
  id: number;
  plan: number;
  author: IUserVM | number;
  authorRole: string;
  message: string;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
  sharedWithHr?: boolean;
  pinned?: boolean;
  readBy?: IUserVM;
  readAt?: string;
}

export interface INotesResultVM {
  count: number;
  results: INoteVM[];
}

export enum ETwoFactorType {
  SMS = 'sms',
  SMS_DJANGO = 'sms_twilio',
  TOTP = 'totp',
  TOTP_DJANGO = 'app',
}

export interface LoginResponse {
  needs2fa: boolean;
  id: string;
  has2faVerified: boolean;
  type: ETwoFactorType;
  existingPhone: string;
  token?: string;
  // Used for 2FA after password login but
  // before code if checked
  ephemeralToken?: string | undefined;
}

export interface DjangoList<T> {
  count: number;
  next: string;
  previous: string;
  results: T[];
}

export enum EFileCategory {
  MED_CERT_DOC = 'MED_CERT_DOC',
  BENEFIT_PROMISSORY_NOTE = 'BENEFIT_PROMISSORY_NOTE',
  STATE_AND_DISABILITY_DOC = 'STATE_AND_DISABILITY_DOC',
  RETURN_TO_WORK_CONFIRMATION = 'RETURN_TO_WORK_CONFIRMATION',
  FMLA_DOCUMENTATION = 'FMLA_DOCUMENTATION',
  OTHER = 'OTHER',
  HRIS_IMPORT = 'HRIS_IMPORT',
  PAYROLL_IMPORT = 'PAYROLL_IMPORT',
  COHORTS_IMPORT = 'COHORTS_IMPORT',
}

export interface IFileImportLog {
  id: number;
  sourceFile: number;
  status: number;
  successCount: number;
  partialSuccessCount: number;
  errorCount: number;
  errorMessage: string | null;
  detailFile: string | null;
  createdAt: string;
  updatedAt: string;
  dryRun: boolean;
}

export interface IAttachmentVM {
  extension: string;
  isSuperUser: boolean;
  name: string;
  uploaderId: number;
  url: string;
}

export interface IFileVM {
  id: number;
  title?: string;
  name?: string;
  url?: string;
  extension?: string;
  category?: EFileCategory;
  sharedWithHr?: boolean;
  createdAt: string;
  updatedAt: string;
  uploader?: IUserVM;
  plan?: IPlanVM;
  leave?: IUserLeaveVM;
  step?: IStepVM;
  shared?: IUserVM;
  company?: number;
  importLog?: IFileImportLog;
}

export interface IFlagVM {
  id: number;
  name: string;
  superuser: boolean;
  everyone: boolean | null;
  createdAt: string;
  updatedAt: string;
  note?: string;
  companies?: ICompanyVM[];
}

export interface IPlanFileSerialized {
  id: number;
  title?: string;
  name?: string;
  url?: string;
  extension?: string;
  category?: EFileCategory;
  sharedWithHr?: boolean;
  createdAt: string;
  updatedAt: string;
  sharedId: number | null;
  uploaderId: number | null;
  planId: number | null;
  stepId: number | null;
  leaveId: number | null;
  companyId: number | null;
}

export enum EDocumentType {
  FMLA_RIGHTS = 'FMLA_RIGHTS',
  FMLA_DESIGNATION = 'FMLA_DESIGNATION',
  FMLA_EE_MED_CERT = 'FMLA_EE_MED_CERT',
  NON_FMLA_EE_MED_CERT = 'NON_FMLA_EE_MED_CERT',
  FMLA_FAMILY_MED_CERT = 'FMLA_FAMILY_MED_CERT',
  NON_FMLA_FAMILY_MED_CERT = 'NON_FMLA_FAMILY_MED_CERT',
  RETURN_TO_WORK = 'RETURN_TO_WORK',
}

export interface EDocumentResultVM {
  docFolderId: string;
}

export enum EMessageMedium {
  EMAIL = 'EMAIL',
  SMS = 'SMS',
  APP = 'APP',
}

export enum EMessageStatus {
  SENT = 1,
  DISMISSED,
  QUEUED,
  ERROR,
}
export interface IMessageVM {
  id: number;
  initiator?: IPartialUserVM | null;
  receiver?: IPartialUserVM;
  code: string;
  toField: string;
  fromField: string;
  subject: string;
  body: string;
  error?: string;
  medium: EMessageMedium;
  createdAt: string;
  leave?: IUserLeaveVM;
  status?: EMessageStatus;
  sftpMethod?: string;
}

export interface IMessageResultVM {
  count: number;
  results: IMessageVM[];
}

export enum ECalendarType {
  PAY_PERIOD = 'PAY_PERIOD',
}

export interface ICalendarDataVM {
  from: string;
  to: string;
}

export interface IPayPeriodClosure {
  approved: string;
  approver: number | null;
  approverName: string | null;
  calendar: number;
  cutoffDate: string;
  fromDate: string;
  id: number;
  toDate: string;
}

export interface IPayCalcCurrentPayPeriod {
  calendar: string;
  calendarId: number;
  daysRemaining: number;
  remoteSource: ECalendarRemoteSource;
  payPeriodLength: number;
  payPeriod: ICalendarDataVM;
  payPeriodClosure?: IPayPeriodClosure;
}

export enum ECalendarRemoteSource {
  MANUAL = 1,
  RIPPLING = 2,
  INTEGRATED = 3,
}

export interface ICalendarVM {
  id: number;
  company: ICompanyVM;
  uploader: IUserVM;
  startDate: string;
  endDate: string;
  calendarType: ECalendarType;
  title: string;
  notes: string;
  calendarData?: ICalendarDataVM[];
  recentPayPeriods?: ICalendarDataVM[];
  applicableContractTypes?: (EExemptionType | EEmployeeType)[];
  payPeriodLength: EPayPeriodLength;
  standardStartDates?: boolean;
  consistentStartDates?: boolean;
  remoteCalendarId: string;
  remoteSource: ECalendarRemoteSource;
  integrationProvider?: EIntegrationProvider;
  // TODO do we need these?
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
  lockUntilDate?: string | null;
}

export interface ICohortUserVM {
  id: number;
  user: IUserVM;
}

export interface ICohortVM {
  id: number;
  company: number;
  name: string;
  cohortUsers?: ICohortUserVM[];
  description?: string;
  hrOwners?: IUserVM[];
  payrollOwners?: IUserVM[];
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
}

export interface IIntegrationAdapterVM {
  id: number;
  integration: number;
  type: string;
  syncStatus: string;
  failures: number;
  lastError?: string;
  lastSyncAt?: string;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
}

export interface IIntegrationVM {
  authStatus: string;
  company: number;
  createdAt: string;
  id: number;
  provider: EIntegrationProvider;
  subscriptions?: string[] | null;
  integrationAdapters?: IIntegrationAdapterVM[];
  domain?: string;
  tenant?: string;
  clientId?: string;
  clientSecret?: string;
  accessToken?: string;
  refreshToken?: string;
}

export enum EIntegrationProvider {
  RIPPLING = 'RIPPLING',
  WORKDAY = 'WORKDAY',
}

export interface ISearchResultVM {
  representation: string;
  relativePath: string;
}
export interface ScheduleRange {
  to: string;
  from: string;
  title: string;
  notes: string;
  type: EScheduleType;
  minutesPerDayMask?: number[];
  totalMinutes?: number;
}

export type ICalendarEvent = {
  end: Date;
  start: Date;
  title: string;
  canResize?: boolean;
  desc?: string;
  editablePeriod?: IScheduleDaysVM;
  earningType?: EEarningTypes;
  color?: string;
  scheduleDayId?: number;
};

export enum EDueOrStartDates {
  LEAVE_CREATE_DATE = 'leave.createDate',
  LEAVE_PUBLISH_DATE = 'leave.publishDate',
  LEAVE_EXPECTED_LEAVE_DATE = 'leave.expectedLeaveDate',
  LEAVE_EXPECTED_DUE_DATE = 'leave.expectedDueDate',
  LEAVE_EXPECTED_RETURN_DATE = 'leave.expectedReturnDate',
  TODAY = 'today',
  PAYCALC_ITEM_START_DATE = 'paycalcItem',
  MED_CERT_DUE_DATE = 'med_cert_due_date',
  BENEFIT_PROMISSORY_NOTE_DUE_DATE = 'benefit_promissory_note_due_date',
}

export type MonthlyPayHistory = {
  month: string;
  grossRegularPay: number | string;
  label?: string;
};

export type IUUIDsVM = {
  EE_WAGES_HOURLY_TEMPLATE_UUID: string;
  EE_WAGES_SALARY_TEMPLATE_UUID: string;
  RIPPLING_PERSONAL_TIME_TEMPLATE_UUID: string;
  RIPPLING_PERSONAL_TIME_TOP_UP_TEMPLATE_UUID: string;
  RIPPLING_VACATION_TEMPLATE_UUID: string;
  RIPPLING_VACATION_TOP_UP_TEMPLATE_UUID: string;
  RIPPLING_SICK_LEAVE_TEMPLATE_UUID: string;
  RIPPLING_SICK_LEAVE_TOP_UP_TEMPLATE_UUID: string;
  RIPPLING_HOLIDAY_TEMPLATE_UUID: string;
  FMLA_BENEFIT_UUID: string;
  CFRA_BENEFIT_UUID: string;
  CA_PDL_BENEFIT_UUID: string;
  CA_PFL_BENEFIT_UUID: string;
  CA_SDI_BENEFIT_UUID: string;
  CO_CFCA_BENEFIT_UUID: string;
  CO_FAMLI_INCOME_REPLACEMENT_BENEFIT_UUID: string;
  CO_FAMLI_JOB_PROTECTION_BENEFIT_UUID: string;
  CT_FMLA_BENEFIT_UUID: string;
  CT_PFML_BENEFIT_UUID: string;
  DC_FMLA_FAMILY_BENEFIT_UUID: string;
  DC_FMLA_MEDICAL_BENEFIT_UUID: string;
  DC_PFL_BENEFIT_UUID: string;
  MA_PFML_JOB_PROTECTION_BENEFIT_UUID: string;
  MA_PFML_PAID_BENEFIT_UUID: string;
  MA_PLA_BENEFIT_UUID: string;
  MD_PLA_BENEFIT_UUID: string;
  ME_FML_BENEFIT_UUID: string;
  NY_PFML_BENEFIT_UUID: string;
  NY_PFL_BENEFIT_UUID: string;
  NY_DBL_BENEFIT_UUID: string;
  OFLA_BENEFIT_UUID: string;
  TN_FLA_BENEFIT_UUID: string;
  WA_PFML_INCOME_REPLACEMENT_BENEFIT_UUID: string;
  WA_PFML_BENEFIT_UUID: string;
};

export interface IIntegrationLeaveLog {
  id: number;
  content: Record<string, unknown>;
  leaveHoursSummed: number;
  loggedAt: Date;
  objectId: number;
  status: string;
  timeOffEndDate: string;
  timeOffStartDate: string;
}

export interface IIntegrationPayLog {
  id: number;
  content: Record<string, unknown>;
  earnings: number | null;
  loggedAt: Date;
  objectId: number;
  status: string;
  payPeriodEndDate: string;
  payPeriodStartDate: string;
}

export type BenefitStatusInfo = {
  status: number;
  reasoning: string[];
};

export enum EBenefitStatus {
  INVALID = 0,
  PENDING = 1,
  APPROVED = 2,
  DENIED = 3,
}

export enum EDenialType {
  PAPERWORK_NOT_RECEIVED = 1,
  LEAVE_ENTITLEMENT_EXHAUSTED = 2,
  NOT_A_REASONABLE_ACCOMMODATION = 3,
  FAILURE_TO_ENGAGE_IN_INTERACTIVE_PROCESS = 4,
  INELIGIBLE_HOURS_WORKED = 5,
  INELIGIBLE_HIRE_DATE = 6,
  INELIGIBLE_HOUR_AND_HIRE_DATE = 7,
  INELIGIBLE_NOT_COVERED_RELATIONSHIP = 8,
  INELIGIBLE_50_75 = 9,
  OTHER = 10,
  NOT_A_DISABILITY = 11,
  INCOMPLETE_DOCUMENTATION = 12,
  INSUFFICIENT_DOCUMENTATION = 13,
  NOT_A_SERIOUS_HEALTH_CONDITION = 14,
}

export interface IBenefitDenial {
  id: number;
  benefitUuid: string;
  startDate: string;
  endDate: string;
  leave: number;
  denialReason: EDenialType;
  additionalReasoningNotes?: string;
  uuid?: string;
  paycalcItemId?: number;
}

export interface SyncStatus {
  sync_in_progress: boolean;
}

export enum EIntegrationHealth {
  SICK = 'SICK',
  HEALTHY = 'HEALTHY',
}

export interface IDeniedTime {
  startDate: string;
  endDate: string;
  denialReason: EDenialType;
  additionalReasoningNotes?: string;
  id: number | string;
}

export interface IAuthGroupVM {
  id: number;
  name: string;
}

export interface IRolePermissionVM {
  id: number;
  user: IUserVM;
  group: IAuthGroupVM;
  startsAt?: string;
  expiresAt?: string;
  objectId?: number;
  contentType?: number;
  roleObject?: ICompanyVM | ICohortVM;
  roleObjectName?: string;
}

export enum EGroupName {
  LSM = 'LSM',
  ADMIN = 'Admin',
  LEADS = 'Leads',
  HR_ADMIN = 'HR Admin',
  HR_USER = 'HR User',
  PAYROLL_ADMIN = 'Payroll Admin',
  PAYROLL_USER = 'Payroll User',
}

export enum EFMLADocuments {
  FMLA_DESIGNATION_NOTICE = 'designation_notice',
  FMLA_MED_CERT = 'med_cert',
  NOTICE_OF_ELIGIBILITY_AND_RIGHTS_AND_RESPONSIBILITIES = 'rights_notice_present',
}

export interface IFMLADocumentCheck {
  leaveId: number;
  missingDocuments: EFMLADocuments[];
  removedDocuments: EFMLADocuments[];
}

export interface IHolidayVM {
  id?: number;
  name: string;
  dateObserved: string;
  paid: boolean;
  companyId?: number;
  countryCode?: string;
  holidayId?: number;
}

export interface IHolidaySubmitVM extends Omit<IHolidayVM, 'dateObserved'> {
  dateObserved: Date;
}
