import useApi from '@api/transportLayer';
import { IUUIDsVM } from '@types';
import React, { createContext, useContext, useReducer } from 'react';

import { useIdentity } from './auth-context';

export const SET_UUIDS = 'SET_UUIDS';

type Dispatch = (action) => void;
type State = { uuids: IUUIDsVM; ripplingTopUpUUIDs: string[]; ripplingPtoUUIDs: string[] };
type UUIDProviderProps = { children: React.ReactNode };

export const UUIDContext = createContext<{ state: State; dispatch: Dispatch } | undefined>(undefined);

export function uuidReducer(state: State, action) {
  switch (action.type) {
    case SET_UUIDS: {
      const ripplingTopUpUUIDs = [
        action.payload?.RIPPLING_VACATION_TOP_UP_TEMPLATE_UUID,
        action.payload?.RIPPLING_PERSONAL_TIME_TOP_UP_TEMPLATE_UUID,
        action.payload?.RIPPLING_SICK_LEAVE_TOP_UP_TEMPLATE_UUID,
      ];
      const ripplingPtoUUIDs = [
        action.payload?.RIPPLING_PERSONAL_TIME_TEMPLATE_UUID,
        action.payload?.RIPPLING_VACATION_TEMPLATE_UUID,
        action.payload?.RIPPLING_SICK_LEAVE_TEMPLATE_UUID,
      ];
      return {
        ...state,
        uuids: action.payload,
        ripplingTopUpUUIDs,
        ripplingPtoUUIDs,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UUIDProvider({ children }: UUIDProviderProps) {
  const [state, dispatch] = useReducer(uuidReducer, { uuids: null, ripplingTopUpUUIDs: [], ripplingPtoUUIDs: [] });
  const value = { state, dispatch };
  const {
    state: { me },
  } = useIdentity();

  useApi.UUIDs.getPlatformUUIDs(
    {},
    { enabled: !!me, onSuccess: (data) => dispatch({ type: SET_UUIDS, payload: data }) },
  );

  return <UUIDContext.Provider value={value}>{children}</UUIDContext.Provider>;
}

function useUUIDs() {
  const context = useContext(UUIDContext);
  if (context === undefined) {
    throw new Error('useUUIDs must be used within a UUIDProvider');
  }
  return context;
}
export { UUIDProvider, useUUIDs };
