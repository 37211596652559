import axios, { AxiosError, AxiosRequestConfig, Method } from 'axios';
import jwt_decode from 'jwt-decode';
import qs from 'qs';
import { useMutation as _useMutation, useQuery as _useQuery } from '@tanstack/react-query';
import { getLoginRedirectUrl } from 'router';
import {
  getAccessToken,
  saveAccessToken,
  saveDeviceUID,
  saveImpersonateAccessToken,
  saveRefreshToken,
} from 'utils/auth-tokens';
import { decorateBodyForUpload, getApiBaseUrl } from 'utils/request';

import {
  CONFIRM_ACTIVATE_2FA_URL_PATH_REGEX,
  DEFAULT_RETRY_LIMIT,
  HTTP_RETRY_CODES,
  IMPERSONATE_URL_PATH,
  LOGOUT_URL_PATH,
  REFRESH_TOKEN_URL_PATH,
  VERIFY_2FA_URL_PATH,
} from './constants';
import QueryKeys from './queryKeys';

export type ErrorDetail = {
  data: {
    detail?: string;
    nonFieldErrors?: string[];
    status: number;
  };
};

// TODO(ts): move to app-specific .d.ts file
export interface IObjectParams {
  [key: string]: any;
}
interface IRequestOptions {
  isUpload?: boolean;
  useAccessToken?: boolean;
  temporaryAccessToken?: string;
}

export function query<T>(method: Method, url, params: IObjectParams = {}, options = {}, deps = {}, key?: QueryKeys) {
  return _useQuery<T>(
    [key || method, url, { ...params }, { ...deps }],
    async () => {
      const { data } = await request<T>(method, url, { ...params }, options);
      return data;
    },
    {
      ...options,
      retry: (failureCount, error) => {
        return reactQueryRetry(failureCount, error, options);
      },
    },
  );
}

export function mutate<T>(method: Method, url: string, options = {}) {
  // No mutation key required for mutations (recc. to use network tab instead of devtools)
  return _useMutation<T, ErrorDetail, IObjectParams, unknown>(
    async (params) => {
      const { data } = await request(method, url, { ...params }, options);
      return data as T;
    },
    {
      ...options,
      retry: (failureCount, error) => {
        return reactQueryRetry(failureCount, error, options);
      },
    },
  );
}

export function mutateBatch<T>(method: Method, url: string, options = {}) {
  // Use for batch endpoints that pass an array instead of single object for data
  return _useMutation<T, unknown, Array<any>, unknown>(
    async (params) => {
      const { data } = await request(method, url, [...params], options);
      return data as T;
    },
    {
      ...options,
      retry: (failureCount, error) => {
        return reactQueryRetry(failureCount, error, options);
      },
    },
  );
}

function reactQueryRetry(failureCount: number, error: any, options: any = {}) {
  // Original react-query retry functionality
  if (typeof options?.retry === 'boolean') {
    return options.retry;
  }
  if (typeof options?.retry === 'function') {
    return options.retry(failureCount, error);
  }
  const retryLimit = Number.isInteger(options?.retry) ? options.retry : DEFAULT_RETRY_LIMIT;
  // Only allow retries if the error status falls within designated retry codes
  return failureCount < retryLimit && HTTP_RETRY_CODES.includes(error?.status);
}

export const buildResponseError = (error: AxiosError) => {
  const data = error.response?.data as any;

  // NOTE: we add to the error object to avoid "Non-Error promise rejection"
  (error as any).data = data;
  (error as any).message = data?.message ?? data?.error ?? data?.detail ?? error.message;
  (error as any).status = error.response?.status;
  return error;
};

// Interesting TypeScript related issue: https://github.com/axios/axios/issues/1510#issuecomment-525382535
axios.interceptors.response.use(
  function (response) {
    // TODO(refactor): this switch/case statement is an event listener structure
    switch (response.config.url) {
      case REFRESH_TOKEN_URL_PATH: {
        // TODO(apiv3): create a type/interface for this response.
        saveAccessToken((response.data as any).token);
        break;
      }
      case VERIFY_2FA_URL_PATH:
      case response?.config?.url?.match(CONFIRM_ACTIVATE_2FA_URL_PATH_REGEX)?.input: {
        // TODO(apiv3): create a type/interface for this response.
        saveAccessToken((response.data as any).token);
        saveRefreshToken((response.data as any).refreshToken);
        saveDeviceUID((response.data as any).deviceUID);
        break;
      }
      case LOGOUT_URL_PATH: {
        saveAccessToken('');
        saveRefreshToken('');
        saveImpersonateAccessToken('');
        break;
      }
      case IMPERSONATE_URL_PATH: {
        const data = response.data as any;
        const requestPayload = JSON.parse(response.config.data);
        // NOTE: token is passed via the URL (and consumed and removed by SafeIdentify in pages/_app.ts)
        window.open(data.site + '?impersonateToken=' + data.token, '_blank');

        break;
      }
      default:
    }
    return response;
  },
  async function (error: AxiosError) {
    if (error.response?.status > 400 && error.response?.status < 404) {
      const meta = {
        error: error.response?.status === 401 ? 'Authentication error' : 'Expired',
        status: error.response?.status,
      };
      if (error.response?.status === 403) {
        location.pathname = '/unauthorized';
      } else if (error.response?.status === 401) {
        const { path, backTo } = getLoginRedirectUrl();
        // TODO refactor this so that token clearing is not done in the interceptor
        if (path) {
          saveAccessToken('');
          saveImpersonateAccessToken('');

          location.pathname = path;
          if (backTo) {
            location.search = backTo;
          } else {
            // * NOTE: we delete the search and hash properties here to remove
            // * the trailing '?' in the url
            const url = document.location.href;
            window.history.pushState({}, '', url.split('?')[0]);
          }
        }
        // TODO create an else case that displays an error when already on the login page
        // and a 401 is received, ex. "Session expired. Please log in again."
      } else {
        // TODO: request new access token with refresh token and retry request
        const accessToken = getAccessToken();
        saveAccessToken('');
        saveImpersonateAccessToken('');
        if (accessToken) {
          const TOKEN_REFRESH_SEC = 60 * 60 * 2;
          const { iat } = jwt_decode(accessToken) as any;
          const now = Math.floor(Date.now() / 1000);
          if (now - iat >= TOKEN_REFRESH_SEC) {
            try {
              // TODO(apiv3): request new access token w/ refresh token, give up if it fails.
              // The current app has no concept of a refresh token, so ignore for now.
              // Users will be logged out of app every 2 hours when their access token expires.
              // const {
              //   data: { token },
              // } = await request('post', REFRESH_TOKEN_URL_PATH, {});
              // saveAccessToken(token);
            } catch (err) {
              // refresh failed, give up.
            }
          }
        }
      }
    } else if (error.response?.status === 404 || error.response?.status === 429) {
      // Throw error for error handling
      throw buildResponseError(error);
    }
    throw buildResponseError(error);
  },
);

// performs the http request via axios (fixes url for :id, sets the authorization header, handles errors, etc.)
async function request<T>(
  method: Method,
  _url: string,
  _params: IObjectParams | Array<any>,
  options: IRequestOptions = {},
) {
  let url = _url;
  const params = _params;
  const headers = {};
  // TODO: move me to an api or request utility class/method
  // Adding a polyfill for IE and using .matchAll() is a cleaner solution for this....
  // https://stackoverflow.com/a/55573169/2544072
  const urlParamRegex = RegExp(/:(\w+)/g);
  const urlParamIdentifiers = _url.match(urlParamRegex) ?? [];
  // replace url url params (i.e. /v2/api/leaves/:leaveId + { leaveId: 180 } -> /v2/api/leaves/180 + {})
  urlParamIdentifiers.forEach((identifier) => {
    // @ts-ignore
    url = url.replace(identifier, _params[identifier.replace(':', '')]);
    delete params[identifier?.replace(':', '')];
  });

  const accessToken = options.useAccessToken === false ? false : options.temporaryAccessToken || getAccessToken();
  if (accessToken) {
    headers['x-access-token'] = accessToken;
    headers['Authorization'] = `Bearer ${accessToken}`;
  }

  const apiBaseUrl = getApiBaseUrl();

  // NOTE: I'm fairly certain we don't need to pass these headers anymore.
  // headers['accept'] = 'application/json';
  // headers['Content-Type'] = 'application/json';
  const body = options.isUpload === true ? decorateBodyForUpload(params) : params;

  const config: AxiosRequestConfig = {
    baseURL: apiBaseUrl,
    headers,
    method,
    url,
  };

  if (method.toLowerCase() === 'get') {
    config.params = params;
    config.paramsSerializer = {
      serialize: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
    };
  } else {
    config.data = body;
  }

  return axios.request<T>(config);
}
