/* eslint-disable no-console */
import fetch from 'isomorphic-fetch';
import isObject from 'lodash.isobject';
import { call, put, race, take } from 'redux-saga/effects';
import { datadogLogs } from '@datadog/browser-logs';

import actionKey from './action-key';
import { getAccessToken, saveAccessToken, saveImpersonateAccessToken } from './auth-tokens';

const noop = () => null;

export function getApiBaseUrl() {
  const protocol = process.env.NEXT_PUBLIC_API_PROTOCOL || 'https';
  const apiDomain = process.env.NEXT_PUBLIC_DJANGO_API_URL || 'api.hellotilt.com';

  return `${protocol}://${apiDomain}`;
}

// TODO(APIv3): remove this helper method if possible
export function decorateBodyForUpload(params) {
  const body = new FormData();
  // so - backend needs to unparse all this by hand:
  // unstringify any object/array, and deal with binary files
  Object.keys(params).forEach((name) => {
    if (params[name] === null) {
      return;
    }
    const isArray = Array.isArray(params[name]);
    const haveFile = isArray && params[name].find((f) => f instanceof File);
    if (haveFile) {
      params[name].forEach((value) => {
        if (value instanceof File) {
          body.append(name, value);
        } else {
          body.append(name, stringify(value));
        }
      });
    } else {
      body.append(name, stringify(params[name]));
    }
  });
  return body;
}

export function* somethingWentWrong(type, res, rest) {
  let result;
  try {
    result = yield res.json();
  } catch (e) {
    datadogLogs.logger.error('Error parsing JSON response', { error: e, action: type, response: res });
  }
  const error = {
    type: actionKey.failure(type),
    error: result && result.error ? result.error : 'Something went wrong',
    message: result && result.message,
    status: res.status,
    // Support django serializer key errors by providing the result data
    data: result?.data ? result.data : result,
    ...rest,
  };
  yield put(error);
  return error;
}

/**
 * sometimes fetch fail sync so this is guard
 * to prevent to everything fall apart
 */
const fetchWithGuard = async (url, options) => {
  let result;
  try {
    result = await fetch(url, options);
  } catch (e) {
    datadogLogs.logger.error('Fetch request failed', { error: e, url, options });
    return {
      error: e.message || 'Something went wrong',
    };
  }

  return result;
};

const stringify = (value) => {
  if (isObject(value)) {
    return JSON.stringify(value);
  }
  return value;
};

export const getQueryString = (obj) => {
  var str = [];
  for (var p in obj) {
    var substr = `${encodeURIComponent(p)}`;
    if (obj[p]) {
      substr += `=${encodeURIComponent(obj[p])}`;
    }
    str.push(substr);
  }
  return str.join('&');
};

export function* handler({
  type, // action name (ex. LOGIN, FETCH_USER)
  url,
  options = {},
  responseType = 'json',
  cancelActions = [],
  ...rest
}) {
  yield put({
    url,
    options,
    responseType,
    type: actionKey.init(type),
    ...rest,
  });
  const fullUrl = `${getApiBaseUrl()}${url}`;
  const headers = {};
  const accessToken = getAccessToken();
  if (accessToken) {
    headers['x-access-token'] = accessToken;
    headers['Authorization'] = `Bearer ${accessToken}`;
  }

  const upload =
    options.body &&
    Object.keys(options.body).find((key) => {
      const field = options.body[key];
      if (Array.isArray(field)) {
        return field.find((f) => f instanceof File);
      }
      return field instanceof File;
    });
  let body;
  if (upload) {
    body = decorateBodyForUpload(options.body);
  } else if (options.body) {
    body = JSON.stringify(options.body);
  }
  if (!upload) {
    headers.accept = 'application/json';
    headers['Content-Type'] = 'application/json';
  }
  const { res, cancel } = yield race({
    res: call(fetchWithGuard, fullUrl, {
      ...options,
      body,
      headers: {
        ...headers,
        ...(options.headers || {}),
      },
    }),
    cancel: take([...cancelActions]),
  });
  if (cancel) {
    return null;
  }
  if (res.status === 402 || res.status === 401 || res.status === 403) {
    yield call(somethingWentWrong, type, res, rest);
    saveAccessToken('');
    saveImpersonateAccessToken('');
  }

  if (res.status < 200 || res.status >= 300) {
    // TODO report this error to the some third party service
    return yield call(somethingWentWrong, type, res, rest);
  }

  let payload = {};
  // 204 == No Content. Therefore, we do not expect any JSON.
  if (res.status !== 204) {
    try {
      payload = yield res[responseType]();
    } catch (e) {
      datadogLogs.logger.error('Error parsing response', { error: e, url, response: res, options });
      return yield call(somethingWentWrong, type, res, rest);
    }
    if (rest.allResults && payload?.results) {
      rest.allResults.push(...payload.results);
    }
  }

  yield put({
    payload,
    type: actionKey.success(type),
    ...rest,
  });
  return {
    ...rest,
    payload,
    // token,
    status: res.status,
    type: actionKey.success(type),
  };
}

export default function* request({ resolve = noop, reject = noop, ...rest }) {
  const result = yield handler(rest);

  if (result && result.error) {
    reject(result);
    return result;
  }
  resolve(result);
  return result;
}

export async function fetchApi({ dispatch }, url, options = {}) {
  const fullUrl = new URL(url, getApiBaseUrl()).href;
  const headers = {};
  const accessToken = getAccessToken();
  if (accessToken) {
    headers['x-access-token'] = accessToken;
    headers['Authorization'] = `Bearer ${accessToken}`;
  }
  let body;
  if (options.upload) {
    body = new FormData();
    // so - backend needs to unparse all this by hand:
    // unstringify any object/array, and deal with binary files
    Object.keys(options.body).forEach((name) => {
      const isArray = Array.isArray(options.body[name]);
      const haveFile = isArray && options.body[name].find((f) => f instanceof File);
      if (haveFile) {
        options.body[name].forEach((value) => {
          if (value instanceof File) {
            body.append(name, value);
          } else {
            body.append(name, stringify(value));
          }
        });
      } else {
        body.append(name, stringify(options.body[name]));
      }
    });
  } else {
    headers.accept = 'application/json';
    headers['Content-Type'] = 'application/json';
    if (options.body) {
      body = JSON.stringify(options.body);
    }
  }

  const res = await fetchWithGuard(fullUrl, {
    ...options,
    body,
    headers: {
      ...headers,
      ...(options.headers || {}),
    },
  });

  if (res.error) {
    // probably offline or network issues
    throw res.error;
  }

  if (res.status === 402 || res.status === 401 || res.status === 403) {
    const meta = {
      error: res.status === 401 ? 'Authentication error' : 'Expired',
      status: res.status,
    };
    saveAccessToken('');
    saveImpersonateAccessToken('');
    throw await fetchApiError(res);
  }
  if (res.status >= 300) {
    throw await fetchApiError(res);
  }

  let payload;
  try {
    if (res.status !== 204) {
      payload = await res.json();
    }
  } catch (e) {
    datadogLogs.logger.error('Error parsing API response', { error: e, url, response: res, options });
    throw e;
  }

  return {
    payload,
    status: res.status,
  };
}
async function fetchApiError(res) {
  let result;
  try {
    result = await res.json();
  } catch (e) {
    datadogLogs.logger.error('Error parsing error response', { error: e, response: res });
  }
  const error = new Error(result?.message || res.statusText || 'Something went wrong');
  error.response = result;
  return error;
}

/**
 * Used to fetch paginated data from Django over multiple requests, when the
 * redux reducer expects all data in a single request.
 *
 * Use as `yield call(fetchAll, { ... })`
 * instead of `yield call(request, { ... })`
 */
export function* fetchAll({ ...rest }) {
  let data = { payload: { next: rest.url } };
  const allResults = [];
  while (data?.payload?.next) {
    if (data.payload.next.startsWith('http')) data.payload.next = data.payload.next.replace(/^https?:\/\/[^/]+/, '');
    data = yield call(request, {
      type: rest.type,
      url: data.payload.next,
      resolve: noop,
      reject: noop,
      options: { apiV3: true },
      allResults,
    });
  }
}
